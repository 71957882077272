<template>
    <div id="footer-v2" class="mt-5">
        <p v-if="!showonly" class="text-center">
            2021 SelCare. All Rights Reserved. <br />
            Selgate Healthcare Sdn Bhd, NO. 01- 02, Level 1, 14/6, Jalan Pembangunan, Seksyen 14, 40000 Shah Alam, Selangor Darul Ehsan.
        </p>
        <div v-else-if="showonly && showonly == 'invoice'" class="pt-5">
        </div>
    </div>
</template>

<script>
export default {
    props: ['showonly'],
}
</script>

<style scoped>
p {
    font-size: 11px;
    color: #545D69;
}
</style>