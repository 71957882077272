<template>
    <div>
        <div class="container mb-5">
            <div class="sub-container pt-2">
                <div class="text-center d-sm-none d-block mb-4">
                    <img src="/img/logo_selcare_512.png" class="home-logo" alt="" loading="lazy">
                </div>
                <div class="main-title">
                    <h2>Hello, how can we help you today?</h2>
                </div>
                <div class="mx-0 row">
                    <div class="col-md-3 col-sm-12 col-6 mt-sm-2 pr-sm-3 pr-2">
                        <router-link to="/home-nursing/booking">
                            <div id="card-1" class="card">
                                <img src="/img/card_1.png" alt="">
                                <span class="name">Home Nursing / <br /> Physio</span>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3 col-sm-12 col-6 mt-sm-2 pl-sm-3 pl-2">
                        <router-link to="/store">
                            <div id="card-2" class="card">
                                <img src="/img/card_2.png" alt="">
                                <span class="name">Pharmacy & <br /> Medical Supplies</span>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3 col-sm-12 col-6 mt-sm-2 mt-2-custom pr-sm-3 pr-2">
                        <div id="card-3" class="card no-exist">
                            <img src="/img/card_3.png" alt="">
                            <span class="name">Doctor's <br /> Visit</span>
                            <button class="btn coming-soon" disabled>coming soon</button>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-12 col-6 mt-sm-2 mt-2-custom pl-sm-3 pl-2">
                        <div id="card-4" class="card no-exist">
                            <img src="/img/card_4.png" alt="">
                            <span class="name">Physiotherapy <br /> & Caregivers</span>
                            <button class="btn coming-soon" disabled>coming soon</button>
                        </div>
                    </div>
                </div>
                <div id="home-description" class="mx-0 row mt-5">
                    <div class="col-12">
                        <p class="description-text">
                            Selgate Healthcare is a member of Selgate Group of Companies which focuses on providing the
                            best healthcare solution for the people. We are confident of serving the clients better
                            through a network of general practice clinics, dental clinics, pharmacy and home nursing
                            setup manned by well- trained doctors, dentists, nurses and pharmacists. Call us at <a
                                class="text-muted font-weight-bold" href="tel:+60355217000">03-5521 7000</a> or email us
                            at <a class="text-muted font-weight-bold"
                                href="mailto:info@selgatehealthcare.com">info@selgatehealthcare.com</a>. We are always
                            there to care.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import MobileDataClass from './../../utils/classes/MobileDataClass'
export default {
    data(){
        return {
            // currentView : 'home'
        }
    },
    methods: {
        // callJSCurrentView(){
        //     return this.currentView
        // }
    },
    mounted(){
        //android/ios sendata function callback
        // let Android = new MobileDataClass()
        // Android.sendData('home')
        // window.callJSCurrentView = this.callJSCurrentView
        // console.log('current view : ', window.callJSCurrentView())

        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.sendData) {
          window.webkit.messageHandlers.sendData.postMessage("home");
        }
        try {
            if($Android){
                $Android.sendData("home")
            }

        } catch (err) {
            console.log('.. .')
        }
    }
}
</script>

<style scoped>
.container {
    position: relative;
    height: calc(100vh - 165px);
}
.sub-container {
    padding: 0px 150px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
}
.main-title {
    text-align: center;
    margin-bottom: 30px;
}

.card {
    height: 160px;
    border-radius: 15px;
    border: unset;
    position: relative;
    overflow: hidden;
}
.card span.name {
    color: #ffffff;
    font-weight: 500;
    position: absolute;
    bottom: 15px;
    left: 12px;
}
.card img {
    position: absolute;
    right: 0px;
    height: 100%;
}
.card.no-exist span.name,
.card.no-exist img {
    opacity: 0.4;
}
.card.no-exist button.coming-soon {
    background-color: rgba(144, 152, 161, 0.7);
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    width: 100px;
    border-radius: 20px;
    position: absolute;
    top: 65px;
    left: 45px;
    opacity: 1!important;
}

#card-1 {
    background-color:#E14DB8;
}
#card-2 {
    background-color:#5E38BA;
}
#card-3 {
    background:linear-gradient(138.52deg, #E85D27 4.81%, #FF2E31 100.76%);
}
#card-4 {
    background:linear-gradient(138.52deg, rgba(39, 104, 232, 0.67) 4.81%, rgba(46, 192, 255, 0.67) 100.76%);
}

@media (max-width: 1199px) {
    .sub-container {
        padding: 0px 80px;
    }
}
@media (max-width: 991px) {
    .sub-container {
        padding: 0px 0px;
    }
    .card img {
        position: absolute;
        right: 0px;
        height: 80%;
        bottom: 0px;
    }
}
@media (max-width: 575px) {
    .sub-container {
        padding: 0px 10px;
        /* margin-top: 30px; */
    }
    .container {
        height: calc(100vh - 140px);
    }
    .home-logo {
        height: 100px;
    }
    .main-title h2 {
        font-size: 1.2rem;
    }
    .card img {
        position: absolute;
        right: 0px;
        height: 80%;
        bottom: 0px;
    }
    p.description-text {
        font-size: 11px;
        text-align: justify;
        margin-top: -15px;
        color: #545D69;
    }
    p.description-text .text-muted {
        color: #2b2b2b!important;
    }
}
@media (max-height: 700px) {
    .container {
        height: 100vh;
    }
    .home-logo {
        /* height: 100px;
        margin-top: -20px; */
    }
}

/** Others */
.mt-2-custom{
    margin-top: 15px;
}
</style>