<template>
    <div class="bg-form">
        <div class="container mb-5">
            <div class="sub-container">
                <div class="head-form mb-5">
                    <div class="top-title">
                        <span>Booking for</span>
                        <h4>{{ category }}</h4>
                    </div>
                    <div class="bottom-info row">
                        <div class="col-4 text-center">
                            <span><i class="fas fa-check-circle"></i> Details</span>
                        </div>
                        <div class="col-4 text-center text-not-yet">
                            <span>Assessment</span>
                        </div>
                        <div class="col-4 text-center text-not-yet">
                            <span>Summary</span>
                        </div>
                    </div>
                </div>
                <div id="booking-options" class="mt-2 mb-4 text-center">
                    <h4>Is this booking for you?</h4>
                    <div class="mt-3">
                        <button id="btn-yes" class="btn" :class="{ 'btn-active' : is_someone_else == false }"  @click="is_someone_else = false">For Me</button>
                        <button id="btn-else" class="btn" :class="{ 'btn-active' : is_someone_else == true }" @click="is_someone_else = true">For Someone Else</button>
                    </div>
                </div>
                <div class="cards mb-4">
                    <div class="card" v-if="is_someone_else == true">
                        <div class="card-title text-center mt-4">
                            <span><i class="fas fa-user"></i> Your Information</span>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <label for="" class="label-input-required">Name</label>
                                    <input type="text" class="form-control" placeholder="Your full name" v-model="booker_info.name">
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <toggle-button v-model="bookerIdCardType"
                                    :labels="{checked: 'NRIC', unchecked: 'Passport no'}" class="" :width="100"
                                    :color="{checked: '#ac1a1a', unchecked: '#fb122a'}"/>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <label for="" class="label-input-required d-sm-none d-block">{{ bookerIdCardType == true ? 'NRIC' : 'Passport'  }}</label>
                                </div> 
                                <div class="col-sm-4 col-12">
                                    <label for="" class="label-input-required d-sm-block d-none">{{ bookerIdCardType == true ? 'NRIC' : 'Passport'  }}</label>
                                    <input type="text" class="form-control" placeholder="000000-00-0000" v-model="booker_info.id_card_number">
                                </div>
                                <div class="col-sm-4 col-6 mt-sm-0 mt-2">
                                    <!-- <input type="text" class="form-control" placeholder="Upload Front NRIC"> -->
                                    <label for="" class="d-sm-block d-none">Upload file (optional)</label>
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="frontIDCardFileBookerInfo" @change="checkFileUpload" ref="frontIDCardFileBookerInfo">
                                        <label class="custom-file-label" for="frontIDCardFileBookerInfo">{{ booker_info.id_card_front_file? trimFileName(booker_info.id_card_front_file.name) : `${ bookerIdCardType == true ? 'Front NRIC' : 'Passport frontpage'}` }}</label>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-6 mt-sm-0 mt-2">
                                    <!-- <input type="text" class="form-control" placeholder="Upload Back NRIC"> -->
                                    <label for="" class="d-sm-block d-none">Upload file (optional)</label>
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="backIDCardFileBookerInfo" @change="checkFileUpload" ref="backIDCardFileBookerInfo">
                                        <label class="custom-file-label" for="backIDCardFileBookerInfo">{{ booker_info.id_card_back_file? trimFileName(booker_info.id_card_back_file.name) : `${ bookerIdCardType == true ? 'Back NRIC' : 'Passport backpage'}` }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-sm-4 col-12">
                                    <label for="" class="label-input-required">Gender</label>
                                    <select class="custom-select" v-model="booker_info.gender">
                                        <option v-if="!booker_info.gender" :value="null" selected disabled>Choose gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </div>
                                <div class="col-sm-4 col-12 mt-sm-0 mt-4">
                                    <label for="" class="label-input-required">Race</label>
                                    <select class="custom-select" v-model="booker_info.race">
                                        <option v-if="!booker_info.race" :value="null" selected disabled>Choose race</option>
                                        <option value="Malay">Malay</option>
                                        <option value="Chinese">Chinese</option>
                                        <option value="Indian">Indian</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                                <div class="col-sm-4 col-12 mt-sm-0 mt-4">
                                    <label for="" class="label-input-required">Religion</label>
                                    <select class="custom-select" v-model="booker_info.religion">
                                        <option v-if="!booker_info.religion" :value="null" selected disabled>Choose religion</option>
                                        <option value="Islam">Islam</option>
                                        <option value="Christian">Christian</option>
                                        <option value="Buddhist">Buddhist</option>
                                        <option value="Hindu">Hindu</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-6 col-12 mt-2">
                                    <label for="" class="label-input-required">Phone no</label>
                                    <div class="row">
                                        <div class="col-4">
                                            <!-- <input type="number" class="form-control" placeholder="+06"> -->
                                            <select class="custom-select" v-model="phoneNumber[0].code">
                                                <!-- <option selected disabled>+06</option> -->
                                                <option value="60">+60</option>
                                            </select>
                                        </div>
                                        <div class="col-8">
                                            <input type="text" class="form-control" placeholder="9581 2411" v-model="phoneNumber[0].number">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12 mt-2">
                                    <label for="">Alternate phone no</label>
                                    <div class="row">
                                        <div class="col-4">
                                            <!-- <input type="number" class="form-control" placeholder="+06"> -->
                                            <select class="custom-select" v-model="phoneNumber[1].code">
                                                <!-- <option selected disabled>+06</option> -->
                                                <option value="60">+60</option>
                                            </select>
                                        </div>
                                        <div class="col-8">
                                            <input type="text" class="form-control" placeholder="9581 2411" v-model="phoneNumber[1].number">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <label for="" class="label-input-required">Email</label>
                                    <input type="email" class="form-control" placeholder="Your email" v-model="booker_info.email">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-4">
                        <div class="card-title text-center mt-4">
                            <span><i class="fas fa-user"></i> Patient Information</span>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <label for="" class="label-input-required">Name</label>
                                    <input type="text" class="form-control" placeholder="Your full name" v-model="patient_info.name">
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <toggle-button v-model="patientIdCardType"
                                    :labels="{checked: 'NRIC', unchecked: 'Passport no'}" class="" :width="100"
                                    :color="{checked: '#ac1a1a', unchecked: '#fb122a'}"/>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <label for="" class="label-input-required d-sm-none d-block">{{ patientIdCardType == true ? 'NRIC' : 'Passport'  }}</label>
                                </div> 
                                <div class="col-sm-4 col-12">
                                    <label for="" class="label-input-required d-sm-block d-none">{{ patientIdCardType == true ? 'NRIC' : 'Passport'  }}</label>
                                    <input type="text" class="form-control" :placeholder="`${ patientIdCardType == true ? '000000-00-0000' : 'A00000000'}`" v-model="patient_info.id_card_number">
                                </div>
                                <div class="col-sm-4 col-6 mt-sm-0 mt-2">
                                    <!-- <input type="text" class="form-control" placeholder="Upload Front NRIC"> -->
                                    <label for="" class="d-sm-block d-none">Upload file (optional)</label>
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="frontIDCardFilePatientInfo" @change="checkFileUpload" ref="frontIDCardFilePatientInfo">
                                        <label class="custom-file-label" for="frontIDCardFilePatientInfo">{{ patient_info.id_card_front_file? trimFileName(patient_info.id_card_front_file.name) : `${ patientIdCardType == true ? 'Front NRIC' : 'Passport frontpage'}` }}</label>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-6 mt-sm-0 mt-2">
                                    <!-- <input type="text" class="form-control" placeholder="Upload Back NRIC"> -->
                                    <label for="" class="d-sm-block d-none">Upload file (optional)</label>
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="backIDCardFilePatientInfo" @change="checkFileUpload" ref="backIDCardFilePatientInfo">
                                        <label class="custom-file-label" for="backIDCardFilePatientInfo">{{ patient_info.id_card_back_file? trimFileName(patient_info.id_card_back_file.name) : `${ patientIdCardType == true ? 'Back NRIC' : 'Passport backpage'}` }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-sm-4 col-12">
                                    <label for="" class="label-input-required">Gender</label>
                                    <select class="custom-select" v-model="patient_info.gender">
                                        <option v-if="!patient_info.gender" :value="null" selected disabled>Choose gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </div>
                                <div class="col-sm-4 col-12 mt-sm-0 mt-4">
                                    <label for="" class="label-input-required">Race</label>
                                    <select class="custom-select" v-model="patient_info.race">
                                        <option v-if="!patient_info.race" :value="null" selected disabled>Choose race</option>
                                        <option value="Malay">Malay</option>
                                        <option value="Chinese">Chinese</option>
                                        <option value="Indian">Indian</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                                <div class="col-sm-4 col-12 mt-sm-0 mt-4">
                                    <label for="" class="label-input-required">Religion</label>
                                    <select class="custom-select" v-model="patient_info.religion">
                                        <option v-if="!patient_info.religion" :value="null" selected disabled>Choose religion</option>
                                        <option value="Islam">Islam</option>
                                        <option value="Christian">Christian</option>
                                        <option value="Buddhist">Buddhist</option>
                                        <option value="Hindu">Hindu</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-6 col-12 mt-2">
                                    <label for="" class="label-input-required">Phone no</label>
                                    <div class="row">
                                        <div class="col-sm-4 col-4">
                                            <!-- <input type="number" class="form-control" placeholder="+06"> -->
                                            <select class="custom-select" v-model="phoneNumber[2].code">
                                                <!-- <option selected disabled>+06</option> -->
                                                <option value="60">+60</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-8 col-8">
                                            <input type="text" class="form-control" placeholder="9581 2411" v-model="phoneNumber[2].number">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12 mt-2">
                                    <label for="">Alternate phone no</label>
                                    <div class="row">
                                        <div class="col-sm-4 col-4">
                                            <!-- <input type="number" class="form-control" placeholder="+06"> -->
                                            <select class="custom-select" v-model="phoneNumber[3].code">
                                                <!-- <option selected disabled>+06</option> -->
                                                <option value="60">+60</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-8 col-8">
                                            <input type="text" class="form-control" placeholder="9581 2411" v-model="phoneNumber[3].number">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <label for="" class="label-input-required">Email</label>
                                    <input type="email" class="form-control" placeholder="Your email" v-model="patient_info.email">
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <label for="" class="label-input-required">Street Name 1</label>
                                    <input type="text" class="form-control" placeholder="Street name" v-model="patient_info.address.address1">
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <label for="">Street Name 2</label>
                                    <input type="text" class="form-control" placeholder="Street name" v-model="patient_info.address.address2">
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-sm-4 col-12">
                                    <label for="" class="label-input-required">State</label>
                                    <select class="custom-select" v-model="patient_info.address.province">
                                        <option v-if="!patient_info.address.province" :value="null" selected disabled>Select state</option>
                                        <option value="Johor">Johor</option>
                                        <option value="Kedah">Kedah</option>
                                        <option value="Kelantan">Kelantan</option>
                                        <option value="Kuala Lumpur">Kuala Lumpur</option>
                                        <option value="Labuan">Labuan</option>
                                        <option value="Melaka">Melaka</option>
                                        <option value="Negeri Sembilan">Negeri Sembilan</option>
                                        <option value="Pahang">Pahang</option>
                                        <option value="Penang">Penang</option>
                                        <option value="Perak">Perak</option>
                                        <option value="Perlis">Perlis</option>
                                        <option value="Putrajaya">Putrajaya</option>
                                        <option value="Sabah">Sabah</option>
                                        <option value="Sarawak">Sarawak</option>
                                        <option value="Selangor">Selangor</option>
                                        <option value="Terengganu">Terengganu</option>
                                    </select>
                                </div>
                                <div class="col-sm-4 col-12 mt-sm-0 mt-4">
                                    <label for="" class="label-input-required">City</label>
                                    <input type="text" class="form-control" placeholder="Your city" v-model="patient_info.address.city">
                                </div>
                                <div class="col-sm-4 col-12 mt-sm-0 mt-4">
                                    <label for="" class="label-input-required">Postcode</label>
                                    <input type="text" class="form-control" placeholder="Your postcode" v-model="patient_info.address.zipcode">
                                </div>
                            </div>
                            <div class="row mt-4" v-if="is_someone_else == true">
                                <div class="col-12">
                                    <label for="">Relationship with you</label>
                                    <input type="text" class="form-control" placeholder="Parent, sibling etc" v-model="patient_info.relationship">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-4">
                        <div class="card-title text-center mt-4">
                            <span><i class="fas fa-user"></i> Patient Information (Optional)</span>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <label for="">Patient Diagnosis</label>
                                    <textarea cols="30" rows="5" class="form-control" placeholder="Describe your condition" v-model="patient_info.patient_diagnosis"></textarea>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-sm-4 col-6">
                                    <label for="" class="">Height</label>
                                    <input type="number" class="form-control" placeholder="in centimeter" v-model="patient_info.height">
                                </div>
                                <div class="col-sm-4 col-6">
                                    <label for="" class="">Weight</label>
                                    <input type="number" class="form-control" placeholder="in kilogram" v-model="patient_info.weight">
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-sm-4 col-6">
                                    <label for="">Allergies</label>
                                </div>
                                <div class="col-sm-4 col-6">
                                    <toggle-button v-model="patient_info.has_allergies"
                                    :labels="{checked: 'Yes', unchecked: 'No'}" class="" :width="50"
                                    :color="{checked: '#19AB4F', unchecked: '#fb122a'}"/>
                                </div>
                            </div>
                            <div v-if="patient_info.has_allergies" class="row mt-2">
                                <div class="col-12">
                                    <textarea cols="30" rows="5" class="form-control" placeholder="Describe your allergies" v-model="patient_info.allergies"></textarea>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-sm-4 col-6">
                                    <label for="">Past Medical History</label>
                                </div>
                                <div class="col-sm-4 col-6">
                                    <toggle-button v-model="patient_info.has_past_medical_history"
                                    :labels="{checked: 'Yes', unchecked: 'No'}" class="" :width="50"
                                    :color="{checked: '#19AB4F', unchecked: '#fb122a'}"/>
                                </div>
                            </div>
                            <div v-if="patient_info.has_past_medical_history" class="row mt-2">
                                <div class="col-12">
                                    <textarea cols="30" rows="5" class="form-control" placeholder="Describe your past medical history" v-model="patient_info.past_medical_history"></textarea>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-sm-4 col-6">
                                    <label for="">Past Surgical History</label>
                                </div>
                                <div class="col-sm-4 col-6">
                                    <toggle-button v-model="patient_info.has_past_surgical_history"
                                    :labels="{checked: 'Yes', unchecked: 'No'}" class="" :width="50"
                                    :color="{checked: '#19AB4F', unchecked: '#fb122a'}"/>
                                </div>
                            </div>
                            <div v-if="patient_info.has_past_surgical_history" class="row mt-2">
                                <div class="col-12">
                                    <textarea cols="30" rows="5" class="form-control" placeholder="Describe your past surgical history" v-model="patient_info.past_surgical_history"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-buttons">
                    <button class="btn btn-cancel" @click="goPrevious">Back</button>
                    <button class="btn btn-submit float-right" @click="goNext">Next</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button'
export default {
    props: [
        'category',
        'is_someone_else',
        'booker_info',
        'patient_info'
    ],
    components: {
        ToggleButton
    },
    data(){
        return {
            phoneNumber: [
                { code: '60', number: '' },
                { code: '60', number: '' },
                { code: '60', number: '' },
                { code: '60', number: '' }
            ],
            bookerIdCardType: true,
            patientIdCardType: true
        }
    },
    methods: {
        checkFileUpload(){
            if(this.$refs.frontIDCardFileBookerInfo && this.$refs.frontIDCardFileBookerInfo.files[0]) this.booker_info.id_card_front_file = this.$refs.frontIDCardFileBookerInfo.files[0]
            if(this.$refs.backIDCardFileBookerInfo && this.$refs.backIDCardFileBookerInfo.files[0]) this.booker_info.id_card_back_file = this.$refs.backIDCardFileBookerInfo.files[0]
            if(this.$refs.frontIDCardFilePatientInfo && this.$refs.frontIDCardFilePatientInfo.files[0]) this.patient_info.id_card_front_file = this.$refs.frontIDCardFilePatientInfo.files[0]
            if(this.$refs.backIDCardFilePatientInfo && this.$refs.backIDCardFilePatientInfo.files[0]) this.patient_info.id_card_back_file = this.$refs.backIDCardFilePatientInfo.files[0]
        },
        trimFileName(_filename){
            let split = _filename.split('.')
            let filename = split[0]
            let extension = split[1]

            if (filename.length > 10) {
                // filename = filename.substring(0, 10)
                filename = filename.substr(0,5) + "..." + filename.substr(-5)
            }

            return filename + '.' + extension;
        },
        updateDataToParent(){
            //merge phone char data
            this.booker_info.phone = this.phoneNumber[0].code + this.phoneNumber[0].number
            this.booker_info.alt_phone = this.phoneNumber[1].code + this.phoneNumber[1].number
            this.patient_info.phone = this.phoneNumber[2].code + this.phoneNumber[2].number
            this.patient_info.alt_phone = this.phoneNumber[3].code + this.phoneNumber[3].number

            //some adjustment for id_card_type
            if(this.bookerIdCardType == true) this.booker_info.id_card_type = 'nric'
            else this.booker_info.id_card_type = 'passport'
            if(this.patientIdCardType == true) this.patient_info.id_card_type = 'nric'
            else this.patient_info.id_card_type = 'passport'

            let objData = {
                is_someone_else: this.is_someone_else,
                booker_info: this.booker_info,
                patient_info: this.patient_info
            }
            this.$emit('detailsSection-data', objData)
        },
        goNext(){
            this.updateDataToParent()
            this.$emit('go-to', 3)
        },
        goPrevious(){
            this.updateDataToParent()
            this.$emit('go-to', 1)
        }
    }
}
</script>

<style scoped>
.bg-form {
    /* background-color: #E5E5E5; */
}

.top-title {
    text-align: center;
    background-color: #EB5757;
    color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 8px 0px;
}

.bottom-info {
    color: #ffffff;
    font-weight: 500;
    text-transform: uppercase;
    background-color: #A91A18;
    margin: -5px 0px 0px 0px;
    padding: 15px 0px;
    border-radius: 8px;
    letter-spacing: 1px;
}
.bottom-info .text-not-yet {
    color: #EB5757;
}

#booking-options .btn {
    font-size: 12px;
    background-color: #DADEE3;
    padding: 10px 25px;
}
#booking-options #btn-yes {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
#booking-options #btn-else {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
#booking-options .btn.btn-active {
    background-color: #7CC6D6;
    color: #ffffff;
}

.cards {

}

.card {
    border: unset;
    padding: 15px 40px;
}

.btn.btn-cancel {
    color: #ffffff;
    background-color: #858C94;
    padding: 15px 25px;
}
.btn.btn-submit {
    color: #ffffff;
    background-color: #7CC6D6;
    padding: 15px 100px;
}


/** Change input stylings */
::-webkit-input-placeholder { /* Edge */
  color: #B3C2CD;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #B3C2CD;
}
::placeholder {
  color: #B3C2CD;
}

label {
    font-size: 13px;
}

select,
textarea,
input {
    font-size: 12px;
}
select { 
    height: 42px;
    padding: 0px 20px;
}
textarea,
input {
    font-size: 12px;
    padding: 20px 20px;
}

.custom-file .custom-file-label {
    height: 42px;
    padding-top: 10px;
}
.custom-file .custom-file-label::after {
    height: 100%;
    padding-top: 10px;
}

/* Change bootstrap input focus */
select:focus,
textarea:focus,
input[type="text"]:focus,
input[type="file"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  border: 2px solid #a91a18;
  box-shadow: unset;
  /* border-color: #a91a18; */
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #a91a18; */
  outline: 0 none;
}

/** Media Queries */
@media (min-width: 992px) {
    .sub-container {
        padding: 0px 150px;
    }
}
@media (max-width: 766px) {
    .card .card-body {
        padding: 0px;
    }
}
@media (max-width: 570px) {
    .bottom-info {
        font-size: 9px;
    }
    .bottom-buttons {
        height: 140px;
    }
}
@media (max-width: 500px) {
    .card {
        padding: 15px 10px;
    }

    .btn.btn-submit {
        width: 100%;
    }
    .btn.btn-cancel {
        width: 100%;
        margin-bottom: 10px;
    }
}
</style>