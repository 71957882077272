import { render, staticRenderFns } from "./ProductView.vue?vue&type=template&id=3b4d02bc&scoped=true&"
import script from "./ProductView.vue?vue&type=script&lang=js&"
export * from "./ProductView.vue?vue&type=script&lang=js&"
import style0 from "./ProductView.vue?vue&type=style&index=0&id=3b4d02bc&scoped=true&lang=css&"
import style1 from "./ProductView.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b4d02bc",
  null
  
)

export default component.exports