<template>
    <div id="product-view" v-if="product">
        <div class="main-container">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12 p-4">
                        <div class="img-box" :style="{'background-image': `url(${getImageLink(product)})`}">
                            <img :src="getPromoBadge(product)" alt="" class="badge-img">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="right-box">
                            <div class="box-header">
                                <div class="breadcrumb">
                                    <router-link to="/store">Home </router-link>
                                    <span class="slash">/</span>
                                    <router-link to="/store/products">Product</router-link>
                                    <span class="slash">/</span>
                                    <span>{{product.title}}</span>

                                </div>
                                <div class="title">
                                    <h5>{{ product.title }}</h5>
                                </div>
                            </div>
                            <div class="box-body">
                                <div class="price">
                                    <div v-if="!isVIP">
                                        <div v-if="productPromoPrice">
                                            <span class="mr-2 compare_at_price">RM {{ (product.variants[0].price).toFixed(2) }}</span>
                                            <span>RM {{ (productPromoPrice).toFixed(2) }}</span>
                                        </div>
                                        <div v-else>
                                            <span>RM {{ product.variants[0].price? (product.variants[0].price).toFixed(2) : 0 }}</span>
                                        </div>
                                    </div>
                                    <span v-else>RM 0.00</span>
                                </div>
                                <div class="action">
                                    <div class="form-inline">
                                        <div v-if="!product.is_sold_out" class="form-group mb-2">
                                            <input type="number" class="form-control" value="1" min="1"
                                                pattern="[0-9]*" v-model.number="quantity">
                                        </div>
                                        <button v-if="!product.is_sold_out" type="submit" class="btn mb-2" @click="addItemObj(product)">Add to Cart</button>
                                        <button v-else class="btn btn-sold-out mb-2" disabled>Item Sold Out</button>
                                        <span v-if="currentCustomer" class="btn btn-wishlist mb-2" @click="submitProductWishlist(product)">
                                            <i v-if="isWishlisted" class="fas fa-heart"></i>
                                            <i v-else class="far fa-heart"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <div class="categories">
                                        <span>Categories : </span>
                                        <span v-if="product.categories.length > 0">
                                            <router-link :to="`/store/products?category=${product.categories[0].id}`">
                                                {{product.categories[0].title}}</router-link>
                                        </span>
                                        <span v-if="product.categories.length > 1">,
                                            <router-link :to="`/store/products?category=${product.categories[1].id}`">
                                                {{product.categories[1].title}}</router-link>
                                        </span>
                                    </div>
                                    <div class="disclaimer">
                                        <p>Disclaimer: The product images shown are for illustration purposes only and
                                            may not be an exact representation of the product.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-md-12 gray-full-box">
                <div class="container">
                    <div class="product-description">
                        <div class="title"><h5>Description</h5></div>
                        <div class="body">
                            <div v-if="product.prod_description" id="description-content" v-html="product.prod_description">
                            </div>
                            <p style="line-height: 2.5;">
                                <span><i>Find out about this product on :</i></span> <br>
                                <span class="findout-logo"><a href="https://www.mims.com/malaysia/drug/search?q=Zocor 40Mg" target="_blank"><img src="//cdn.shopify.com/s/files/1/0391/0621/5981/t/3/assets/logo_MIMS_2.png?v=13673904418187482728" alt="" style="max-width: 100px;"></a></span> 
                                <span class="findout-logo"><a href="https://www.webmd.com/search/search_results/default.aspx?query=Zocor 40Mg" target="_blank"><img src="//cdn.shopify.com/s/files/1/0391/0621/5981/t/3/assets/logo_WebMD_2.png?v=11634527399433977664" alt="" style="max-width: 100px;"></a></span> 
                                <span class="findout-logo"><a href="https://www.drugs.com/search.php?searchterm=Zocor 40Mg" target="_blank"><img src="//cdn.shopify.com/s/files/1/0391/0621/5981/t/3/assets/logo_Drugs.com_2.png?v=16403720267279522611" alt="" style="max-width: 100px;"></a></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="container">
                    <div class="product-listing">
                        <div class="title text-center"><h5>Recommended Products</h5></div>
                        <div class="body text-center" style="padding:100px 0px;">
                            <div id="recommended-products" v-if="recommendedProducts" class="row">
                                <div v-for="product in recommendedProducts" :key="product.id" class="col-lg-3 col-md-6 col-sm-6 col-6 mb-3">
                                    <div class="row mb-3">
                                        <div class="col-md-12 bg-white">
                                            <!-- <router-link :to="`/store/products/${product.id}`"> -->
                                            <div class="img-box-2" :style="{'background-image': `url(${getImageLink(product)})`}">
                                                <img :src="getPromoBadge(product)" alt="" class="badge-img">
                                            </div>
                                            <!-- </router-link> -->
                                        </div>
                                        <div class="col-md-12 text-center prod-label mt-3">
                                            <span class="name"><router-link :to="`/store/products/${product.id}`">{{ product.title }}</router-link></span>
                                            <div v-if="!isVIP">
                                                <span v-if="product.variants[0].compare_at_price && product.variants[0].compare_at_price != 0" class="price compare_at_price">RM {{ product.variants[0].compare_at_price? (product.variants[0].compare_at_price).toFixed(2) : 0 }}</span>
                                                <span class="price">RM {{ product.variants[0].price? (product.variants[0].price).toFixed(2) : 0 }}</span>
                                            </div>
                                            <span v-else class="price">RM 0.00</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from './../../../utils/API'
import { mapMutations, mapGetters } from 'vuex'
import { checkProductPromo } from './../../../utils/ProductUtils'

export default {
    data() {
        return {
            isVIP: false,
            product: null,
            productPromoPrice: null,
            productPromoBadge: null,
            quantity: 1,
            recommendedProducts: null,
        }
    },
    watch: {
        $route(to, from){
            if(to.params.id != from.params.id) this.loadProdView()
        }
    },
    computed: {
        ...mapGetters(['currentCustomer', 'wishlist']),
        isWishlisted(){
            const item = this.wishlist.items.find(i => i.product.id == this.product.id)
            if(item) return true
            else return false
        }
    },
    methods: {
        ...mapMutations(['addItem', 'submitWishlistItem']),
        addItemObj(_product){
            this.addItem({ product: _product , quantity: this.quantity })
        },
        getImageLink(_product) {
            if(!_product.variants[0].image) return '/img/default_no_image.jpg'
            else return API.getAPIUrl() + _product.variants[0].image.url
        },
        initPromo(){
            let prodPromo = checkProductPromo(this.product)
            if(prodPromo) {
                this.productPromoPrice = prodPromo.promo_price
                this.productPromoBadge = prodPromo.promo_badge
            }
            
        },
        getPromoBadge(_product){
            if(this.productPromoBadge) return API.getAPIUrl() + this.productPromoBadge
            // if(_product.is_promotion){
            //     if(_product.promo_type == 'raya') return '/img/badge_raya_promo.png'
            //     else if(_product.promo_type == 'limitedtime') return '/img/badge_4_days_only.png'
            // }
        },
        scrollTop(){
            window.scrollTo(0,0)
        },
        async loadProdView(){
            this.scrollTop()

            //current product
            let res = await API.get(`/products/${this.$route.params.id}`)
            this.product = res.data

            //recommended product
            let res02 = await API.get(`/products?category=${this.product.categories[0].id}&_limit=4&is_random=true`)
            this.recommendedProducts = res02.data.products
        },
        submitProductWishlist(_product){
            this.submitWishlistItem({ product: _product })
            this.isWishlisted = !this.isWishlisted
        }
    },
    async mounted() {
        this.scrollTop()
        await this.loadProdView()

        //Set all price to 0.00 if customer type == vip
        if(this.currentCustomer){
            if(this.currentCustomer.user.customer.cust_type == 'vip') this.isVIP = true
            else this.isVIP = false
        }

        //check product promo
        this.initPromo()
    }
}
</script>

<style scoped>
    .img-box {
        position: relative;
        display: block;
        padding-top: 100%;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }

    .img-box img.badge-img,
    .img-box-2 img.badge-img {
        position: absolute;
        width: 50%;
        top: 5px;
        left: 0px;
    }

    #recommended-products .img-box-2 {
        position: relative;
        display: block;
        padding-top: 100%;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }

    #recommended-products .img-box-2:hover+button {
        opacity: 100;
        transition: 0.2s;
    }

    #recommended-products .name, .price {
        display: block;
        line-height: 1.2rem;
        margin-bottom: 5px;
    }
    #recommended-products .name a {
        color:#666;
        text-decoration: unset;
        font-size: 14px;
        font-weight: bold;
    }
    #recommended-products .price {
        color: #a91a18;
        font-weight: bold;
        font-size: 14px;
    }
    #recommended-products .price.compare_at_price {
        text-decoration: line-through;
        color: gray;
        font-size: 12px;
        margin-bottom: -3px;
    }

    .box-header {
        padding: 15px 0px;
        border-bottom: 2px solid #f6f6f6;
    }

    .box-header .breadcrumb {
        background-color: unset;
        padding: 0px;
        font-size: 14px;
        line-height: 1;
    }

    .box-header .breadcrumb a {
        color: #666;
        text-decoration: unset;
    }

    .box-header .breadcrumb span.slash {
        display: inline-block;
        padding: 0px 5px 0px 5px;
    }

    .box-header .title {
        padding: 10px 0px;
    }

    .box-header .title h5 {
        font-size: 18px;
        letter-spacing: 0.2em;
        /* font-family: "Roboto Slab", serif; */
        text-transform: uppercase;
        font-weight: bold;
        color: #000;
        line-height: 1.4;
    }

    .box-body {
        padding: 15px 0px;
        border-bottom: 2px solid #f6f6f6;
        height: 400px;
    }

    .box-body .price {
        padding: 10px 0px;
        font-size: 18px;
        /* font-family: "Roboto Slab", serif; */
        color: #a91a18;
        font-weight: bold;
        text-transform: uppercase;
    }
    .box-body .price span.compare_at_price {
        text-decoration: line-through;
        color: gray;
        font-size: 12px;
        position: relative;
        top: -3px;
    }

    .box-body .action {
        padding: 15px 0px;
    }

    .box-body .action .form-group {
        width: 60px;
        margin-right: 25px;
    }

    .box-body .action .form-group input[type=number] {
        width: 100%;
        height: 45px;
        border-radius: unset;
        border: 2px solid #e5e5e5;
        color: #999;
    }

    .box-body .action button.btn {
        padding: 10px 35px;
        background-color: #a91a18;
        color: #fff;
        border-radius: unset;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.15em;
        /* font-family: "Karla", sans-serif; */
        font-weight: bold;
    }

    .box-body .action button.btn.btn-sold-out {
        color: #a91a18;
        background-color: #fff;
        border: 3px solid #a91a18;
    }

    .box-body .action span.btn.btn-wishlist {
        cursor: pointer;
        margin-left: 10px;
        padding: 2px 5px;
        background: unset;
        color: #a91a18;
        font-size: 25px;
    }

    .box-body .info .categories {
        margin-bottom: 10px;
    }

    .box-body .info .categories a {
        color: #666;
        text-decoration: unset;
    }

    .box-body .info .disclaimer p {
        color: #999b9c;
        font-style: italic;
        line-height: 1.4;
    }


    .product-description .title {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 2px solid #e3e3e3;
    }
    .product-description .title h5 {
        line-height: normal;
        font-size: 14px;
        /*font-family: "Roboto Slab",serif;*/
        text-transform: uppercase;
        letter-spacing: 0.15em;
        font-weight: bold;
    }
    .product-description .body {
        margin-top: 30px;
    }
    .product-description .body .findout-logo {
        margin-right: 5px;
    }
    .product-description .body .findout-logo img {
        max-width: 100px;
        border: 1px solid #a7a7a7a8;
        border-radius: 7px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    }

    .product-listing {
        padding: 20px 0px;
    }
    .product-listing .title h5 {
        padding: 20px 0px;
        line-height: normal;
        font-size: 16px;
        /*font-family: "Roboto Slab",serif;*/
        text-transform: uppercase;
        letter-spacing: 0.15em;
        font-weight: bold;
    }
    
    /** General */
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        opacity: 1;
    }

    .gray-full-box {
        background-color: #f6f6f6;
        padding: 45px 0px;
    }

</style>

<style>
    /** Override Styling specific for description only */
    #description-content p,
    #description-content ol,
    #description-content ul,
    #description-content pre,
    #description-content blockquote,
    #description-content h1,
    #description-content h2,
    #description-content h3,
    #description-content h4,
    #description-content h5,
    #description-content h6 {
    margin: 0;
    padding: 0;
    }

    #description-content ol,
    #description-content ul {
        padding-left: 1.5em;
    }

    #description-content h1 {
        font-size: 2em;
        line-height: 1.2;
    }
    #description-content h2 {
        font-size: 1.5em;
        line-height: 1.2;
    }
    #description-content h3 {
        font-size: 1.17em;
        line-height: 1.2;
    }
    #description-content h4 {
        font-size: 1em;
        line-height: 1.2;
    }
    #description-content h5 {
        font-size: 0.83em;
        line-height: 1.2;
    }
    #description-content h6 {
        font-size: 0.67em;
        line-height: 1.2;
    }

    #description-content p {
        font-size: 13px;
        line-height: 1.2;
    }

    #description-content li {
        font-size: 13px;
        line-height: 1.2;
    }

    #description-content img {
        height: 150px;
        width: auto;
    }

    #description-content a {
        font-size: 13px;
        line-height: 1.2;
        color: #06c;
        text-decoration: underline;
    }
</style>