import Vue from 'vue'
import VueRouter from 'vue-router'
import CustomerAuthRequired from '../utils/CustomerAuthRequired';
// import AdminAuthRequired from '../utils/AdminAuthRequired';
import CustomerSessionCheck from '../utils/CustomerSessionCheck'
// import AdminSessionCheck from '../utils/AdminSessionCheck'
import DefaultCheckAll from '../utils/DefaultCheckAll'

Vue.use(VueRouter)

  const routes = [
    /** Public path */
    {
      path: '/',
      component: () => import('../views/pages'),
      // redirect: "",
      children: [
        /**  New Homepage */
        {
          path: '/',
          component: () => import('../views/pages/Home')
        },

        /** Online Store */
        {
          path: '/store',
          component: () => import('../views/pages/online-store/Home')
        },

        /** Product pages start here */
        {
          path: '/store/products',
          component: () => import('../views/pages/online-store/Products'),
          meta: { title: 'Products' }
        },
        {
          path: '/store/products/:id',
          component: () => import('../views/pages/online-store/ProductView'),
          meta: { title: 'Product' }
        },
        
        /** Cart page start here */
        {
          path: '/store/cart',
          component: () => import('../views/pages/online-store/Cart'),
          meta: { title: 'Cart' }
        },

        /** Checkout page start here */
        {
          path: '/store/checkout',
          component: () => import('../views/pages/online-store/Checkout'),
          meta: { title: 'Checkout' }
          // beforeEnter: CheckCart
        },
        
        /** Verfication Payment & Thanks page start here */
        {
          path: '/store/checkout/redirect_verify',
          component: () => import('../views/pages/online-store/VerifyPayment'),
          meta: { title: 'Redirect Verification' }
        },
        {
          path: '/store/checkout/response',
          component: () => import('../views/pages/online-store/ResponsePayment'), // maybe boleh ubah letak sekali dengan checkout directory. make it as components etc.
          meta: { title: 'Order Response' }
        },
        {
          path: '/store/feedback',
          component: () => import('../views/pages/online-store/Feedback'),
          meta: { title: 'Feedback' }
        },

        /** Information pages start here */
        {
          path: '/info/about-us',
          component: () => import('../views/pages/information/AboutUs'),
          meta: { title: 'About Us' }
        },
        {
          path: '/info/about-nursing',
          component: () => import('../views/pages/information/AboutNursing'),
          meta: { title: 'About Nursing' }
        },
        {
          path: '/info/privacy-notice',
          component: () => import('../views/pages/information/PrivacyNotice'),
          meta: { title: 'Privacy Notice' }
        },
        {
          path: '/info/return-refund-policy',
          component: () => import('../views/pages/information/ReturnRefundPolicy'),
          meta: { title: 'Return and Refund Policy' }
        },
        {
          path: '/info/shipping-delivery-service',
          component: () => import('../views/pages/information/ShippingDeliveryService'),
          meta: { title: 'Shipping and Delivery Service' }
        },

        /**  Home Nursing page */
        {
          path: '/store/home-nursing/apply',
          component: () => import('../views/pages/online-store/NurseApplication'),
          meta: { title: 'Nurse Application' }
        },
        {
          path: '/home-nursing/booking',
          component: () => import('../views/pages/nurse-booking/NurseBooking'),
          meta: { title: 'Booking for Home Nursing' }
        },
        {
          path: '/home-nursing/booking/:bookingid/invoice/:invoiceid',
          component: () => import('../views/pages/nurse-booking/NurseBookingInvoice'),
          meta: { title: 'Invoice Booking for Home Nursing' }
        },
        {
          path: '/home-nursing/booking/:bookingid/invoice/:invoiceid/after-payment',
          component: () => import('../views/pages/nurse-booking/NurseBookingAfterPayment'),
          meta: { title: 'After Payment Page' }
        },
        {
          path: '/home-nursing/booking/:bookingid/provider-acceptance',
          component: () => import('../views/pages/nurse-booking/NurseBookingProviderAcceptance'),
          meta: { title: 'Provider Acceptance for Booking' }
        },
        {
          path: '/home-nursing/booking/:bookingid/provider-selection',
          component: () => import('../views/pages/nurse-booking/NurseBookingProviderSelection'),
          meta: { title: 'Provider Selection for Booking' }
        }
      ]
    },

    /** Authentication path for customers */
    {
      path: '/auth',
      component: () => import('../views/auth'),
      redirect: '/auth/login',
      beforeEnter: CustomerSessionCheck,
      children: [
        {
          path: 'login',
          component: () => import('../views/auth/CustomerAuth'),
          meta: { title: 'Login' }
        },
        {
          path: 'signup',
          component: () => import('../views/auth/CustomerAuth'),
          meta: { title: 'Sign Up' }
        },
        {
          path: 'resetpassword',
          component: () => import('../views/auth/CustomerAuth'),
          meta: { title: 'Reset Password' }
        }
      ]
    },
    /**  Other Providers' Callback */
    {
      path: '/connect/facebook/redirect',
      component: () => import('../views/auth/ProviderCallback'),
      meta: { title: 'Redirect Facebook Auth' }
    },

    /** Customer's path */
    {
      path: '/customer',
      component: () => import('../views/customer'),
      redirect: '/customer/dashboard',
      beforeEnter: CustomerAuthRequired,
      children: [
        {
          path: '/customer/dashboard',
          component: () => import('../views/customer/Dashboard')
        },
        {
          path: '/customer/dashboard-booking',
          component: () => import('../views/customer/BookingList')
        },
        {
          path: '/customer/orders/:id',
          component: () => import('../views/customer/Order')
        },
        {
          path: '/customer/addresses',
          component: () => import('../views/customer/Address')
        }
      ]
    },

    /**  Maintainance Path (Development or Deployment process only) */
    {
      path:'/will-be-back-soon',
      component: () => import('../views/pages/Maintenance'),
      meta: { title: 'New Deployment' }
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//check the application setting first before navigate anywhere
router.beforeEach(DefaultCheckAll)

//setting header title
router.afterEach((to, from) => {
  Vue.nextTick(() => {
      document.title = to.meta.title? `${to.meta.title} - Selcare.com` : 'Selcare.com'
  })
})

export default router
