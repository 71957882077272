/* eslint-disable */
// Issues : Promise executor functions should not be async  |  no-async-promise-executor

/**
 * PLEASE NOTES !!!
 * FOR NOW : 
 * - We use this API.js just for customer usage.
 * - Soon, another type user like admin need to use this util, then need to modify it.
 * - Espsecially, at _opts function which is just get the currentCustomer data only.
 */

import axios from 'axios'
import config from '../../config'
import store from '../store'

export default class API {
    //get
    static get(module) {
        return new Promise(async (resolve, reject) => {
            try {
                const opts = this._opts(module, 'get')
                const response = await axios(opts)

                resolve(response)
            } catch (err) {
                if(err) reject(err)
            }
        })
    }

    //post
    static post(module, data) {
        return new Promise(async (resolve, reject) => {
            try {
                const opts = this._opts(module, 'post', data)
                const response = await axios(opts)

                resolve(response)
            } catch (err) {
                if(err) reject(err)
            }
        })
    }

    //put
    static put(module, data) {
        return new Promise(async (resolve, reject) => {
            try {
                const opts = this._opts(module, 'put', data)
                const response = await axios(opts)

                resolve(response)
            } catch (err) {
                if(err) reject(err)
            }
        })
    }

    //delete
    static delete(module) {
        return new Promise(async (resolve, reject) => {
            try {
                const opts = this._opts(module, 'delete')
                const response = await axios(opts)

                resolve(response)
            } catch (err) {
                if(err) reject(err)
            }
        })
    }

    //auth - Login
    static authenticate(data) {
        return new Promise(async (resolve, reject) => {
            try {
                const opts = this._opts('/auth/local', 'post', data)
                const response = await axios(opts)

                resolve(response)
            } catch (err) {
                if(err) reject(err)
            }
        })
    }

    //auth - Register
    static authenticate_register(data) {
        return new Promise(async (resolve, reject) => {
            try {
                const opts = this._opts('/auth/local/register', 'post', data)
                const response = await axios(opts)

                resolve(response)
            } catch (err) {
                if(err) reject(err)
            }
        })
    }

    //auth - Forgot Password
    static authenticate_forgot(data) {
        return new Promise(async (resolve, reject) => {
            try {
                const opts = this._opts('/auth/forgot-password', 'post', data)
                const response = await axios(opts)

                resolve(response)
            } catch (err) {
                if(err) reject(err)
            }
        })
    }

    //auth - Reset Password
    static authenticate_reset(data) {
        return new Promise(async (resolve, reject) => {
            try {
                const opts = this._opts('/auth/reset-password', 'post', data)
                const response = await axios(opts)

                resolve(response)
            } catch (err) {
                if(err) reject(err)
            }
        })
    }

    //return api url
    static getAPIUrl() {
        return config.API_URL
    }


    //configure options for config
    static _opts(module, method, data){
        const opts = {
            method: method,
            url: config.API_URL + module
        }
        if(store.getters.currentCustomer) opts.headers = { 'Authorization': `Bearer ${store.getters.currentCustomer.token}` }
        if(data) opts.data = data
        
        return opts
    }
}