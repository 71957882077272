<template>
    <div>
        <!-- Cart Drawer here -->

        <!-- Page Container here  -->
        <div id="page-container">

            <div class="main-container">
                <div class="mt-2 ml-3" style="height:40px">
                    <img src="/img/logo_full.png" alt="" height="100%">
                </div>

                <div class="row justify-content-sm-center mt-5">
                    <div class="col-sm-6 ">
                        <div class="card">
                            <div class="card-body">
                                <div class="row mt-4 mb-4">
                                    <div class="col-sm-6 align-self-center">
                                        <h1>Oops!</h1>
                                        <h2>New Deployment</h2>
                                        <p>We will be back soon after new deployment is done. Just wait for a moment.</p>
                                    </div>
                                    <div class="col-sm-6 text-right">
                                        <img src="/img/rocket.png" alt="" width="100%">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    mounted(){
        
    }
}
</script>

<style>
.main-container {
    padding: 20px 0px;
}

.main-container .container {
    /* font-family: "Karla",arial,sans-serif; */
    font-size: 16px;
}

.main-container .container h1 {
    letter-spacing: 0.2em;
    /*font-family: "Roboto Slab",serif;*/
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
    font-size: 1.71429em;
}
.card {
    border: unset;
}

h1 {
    font-size: 6.0rem;
}
h2 {
    font-size: 3.0rem;
}
</style>