import API from '../../utils/API'
import moment from 'moment'

export default {
    state: {
        checkoutData: null,
        checkoutError: null,
        checkoutSuccess: false,
        checkoutProcessing: false,
        checkoutBtnProcessing: false,
        checkoutOverlay: false
    },
    getters: {
        checkoutData: state => state.checkoutData,
        checkoutError: state => state.checkoutError,
        checkoutSuccess: state => state.checkoutSuccess,
        checkoutProcessing: state => state.checkoutProcessing,
        checkoutBtnProcessing: state => state.checkoutBtnProcessing,
        checkoutOverlay: state => state.checkoutOverlay
    },
    mutations: {
        setCheckoutProcessing(state, payload) {
            state.checkoutProcessing = payload
            state.checkoutError = null
        },
        setCheckoutBtnProcessing(state, payload) {
            state.checkoutBtnProcessing = payload
            state.checkoutError = null
        },
        setCheckoutOverlay(state, payload) {
            state.checkoutOverlay = payload
            state.checkoutError = null
        },
        setCheckoutError(state, payload) {
            state.checkoutSuccess = false
            state.checkoutError = payload
            state.checkoutData = null
            state.checkoutProcessing = false
        },
        clearCheckoutError(state) {
            state.checkoutError = null
        },
        setCheckoutSuccess(state){
            state.checkoutSuccess = true
            state.checkoutProcessing = false
        },
        clearCheckoutSuccess(state){
            state.checkoutSuccess = false
        }
    },
    actions: {
        /**  NOTE THAT RIGHT NOW WE JUST USING BILLPLZ ONLY. */
        async createPayment({commit}, payload){
            commit('clearCheckoutSuccess')
            commit('clearCheckoutError')
            commit('setCheckoutBtnProcessing', true)

            //upload prescription first if needed
            let prescriptionFileId = null
            if(payload.hasPrescription){
                let formData = new FormData()
                formData.append('files', payload.prescriptionFile)
                let resFileUpload = await API.post('/upload', formData)
                prescriptionFileId = resFileUpload.data[0].id
            }

            let line_item = []
            payload.items.forEach(item => {
                line_item.push({
                    title: item.product.title,
                    product_id: item.product.id,
                    variant_id: item.product.variants[0].id,
                    img_url: item.product.variants[0].image? item.product.variants[0].image.url : '/img/default_no_image.jpg',
                    quantity: item.quantity,
                    taxable: true,
                    price: item.product.promo_price ? item.product.promo_price : item.product.variants[0].price,
                    total_discount: 0.00, //discount
                    total_price: item.product.promo_price? (item.product.promo_price * item.quantity) : (item.product.variants[0].price * item.quantity)
                })    
            })

            let data2send = {
                name: moment().format('YYMMDhhmmss'),
                email: payload.email,
                phone: payload.phone,
                fullname: payload.name,
                description: payload.description,
                customer_nric: payload.nric,
                has_prescription: payload.hasPrescription,
                prescription_file: prescriptionFileId,
                gateway: 'kiplepay', //for now is kiplepay
                is_guest: payload.isGuest,
                is_test: payload.testmode, // for now
                total_price: payload.total,
                subtotal_price: payload.subtotal,
                total_shipping: payload.shippingValue,
                total_tax: payload.taxValue,
                tax_included: true,
                total_discounts: payload.totalDiscount, // discount
                customer_id: payload.customerId,
                delivery_address: payload.deliveryAddress,
                delivery_type: payload.deliveryTypeId,
                line_item,
                transaction_type: 'order'
            }

            API.post('/custom/payment/create-order-payment', data2send)
                .then(res => { //get Checkout data
                    commit('setCheckoutBtnProcessing', false)
                    commit('setCheckoutOverlay', true)
                    commit('setCheckoutProcessing', true)
                    // window.location.href = res.data.payment_url

                    //handler for fully free function
                    if(res.data.msg_code == 'fully_free_success'){
                        //clear cart
                        let cart = payload.cart
                        cart.items = []
                        cart.discount_code = null
                        commit('resetCart', cart)
                        window.location.href = `/store/checkout/response?payment=success&order=${res.data.order_id}`
                    }
                    else { //handler for non fully free function
                        //create form submission
                        let form = document.createElement('form')
                        form.method = 'POST'
                        form.action = res.data.post_url

                        let input01 = document.createElement('input')
                        let input02 = document.createElement('input')
                        let input03 = document.createElement('input')
                        let input04 = document.createElement('input')
                        let input05 = document.createElement('input')
                        let input06 = document.createElement('input')
                        let input07 = document.createElement('input')
                        let input08 = document.createElement('input')
                        input01.value = res.data.ord_date
                        input01.name = 'ord_date'
                        input02.value = res.data.ord_totalamt
                        input02.name = 'ord_totalamt'
                        input03.value = res.data.ord_gstamt
                        input03.name = 'ord_gstamt'
                        input04.value = res.data.ord_shipname
                        input04.name = 'ord_shipname'
                        input05.value = res.data.ord_mercref
                        input05.name = 'ord_mercref'
                        input06.value = res.data.ord_mercID
                        input06.name = 'ord_mercID'
                        input07.value = res.data.ord_returnURL
                        input07.name = 'ord_returnURL'
                        input08.value = res.data.merchant_hashvalue
                        input08.name = 'merchant_hashvalue'

                        form.appendChild(input01)
                        form.appendChild(input02)
                        form.appendChild(input03)
                        form.appendChild(input04)
                        form.appendChild(input05)
                        form.appendChild(input06)
                        form.appendChild(input07)
                        form.appendChild(input08)

                        document.body.appendChild(form)
                        form.submit()   
                    }
            })
        },

        async createInvoicePayment({commit}, payload){
            commit('clearCheckoutSuccess')
            commit('clearCheckoutError')
            commit('setCheckoutBtnProcessing', true)

            let data2send = {
                name: moment().format('YYMMDhhmmss'),
                email: payload.email,
                phone: payload.phone,
                fullname: payload.fullname,
                description: payload.description,
                gateway: 'kiplepay', //for now is kiplepay
                is_test: payload.testmode, // for now
                total_price: payload.total_price,
                total_tax: payload.total_tax,
                invoice: payload.invoice,
                transaction_type: 'invoice'
            }

            // console.log('data2send : ', data2send)

            API.post('/custom/payment/create-invoice-payment', data2send)
                .then(res => { //get Checkout data
                    commit('setCheckoutBtnProcessing', false)
                    commit('setCheckoutOverlay', true)
                    commit('setCheckoutProcessing', true)
                    // window.location.href = res.data.payment_url

                    //create form submission
                    let form = document.createElement('form')
                    form.method = 'POST'
                    form.action = res.data.post_url

                    let input01 = document.createElement('input')
                    let input02 = document.createElement('input')
                    let input03 = document.createElement('input')
                    let input04 = document.createElement('input')
                    let input05 = document.createElement('input')
                    let input06 = document.createElement('input')
                    let input07 = document.createElement('input')
                    let input08 = document.createElement('input')
                    let input09 = document.createElement('input')
                    input01.value = res.data.ord_date
                    input01.name = 'ord_date'
                    input02.value = res.data.ord_totalamt
                    input02.name = 'ord_totalamt'
                    input03.value = res.data.ord_gstamt
                    input03.name = 'ord_gstamt'
                    input04.value = res.data.ord_shipname
                    input04.name = 'ord_shipname'
                    input05.value = res.data.ord_mercref
                    input05.name = 'ord_mercref'
                    input06.value = res.data.ord_mercID
                    input06.name = 'ord_mercID'
                    input07.value = res.data.ord_returnURL
                    input07.name = 'ord_returnURL'
                    input08.value = res.data.merchant_hashvalue
                    input08.name = 'merchant_hashvalue'
                    input09.value = res.data.ord_customfield1
                    input09.name = 'ord_customfield1'

                    form.appendChild(input01)
                    form.appendChild(input02)
                    form.appendChild(input03)
                    form.appendChild(input04)
                    form.appendChild(input05)
                    form.appendChild(input06)
                    form.appendChild(input07)
                    form.appendChild(input08)
                    form.appendChild(input09)

                    document.body.appendChild(form)
                    form.submit()
            })
        },

        verifyPayment({commit}, payload){
            commit('setCheckoutProcessing', false)
            if(payload.transaction_type && payload.transaction_type == 'invoice') { //if invoice
                //get invoicedata to get bookingid
                API.get(`/invoices/${payload.order_id}`)
                    .then(res => {
                        window.location.href = `/home-nursing/booking/${res.data.booking_form.id}/invoice/${payload.order_id}/after-payment`
                    })
            }
            else { //if order
                if(payload.payment_status == 'success') {
                    commit('setCheckoutSuccess')
    
                    //clear cart
                    let cart = payload.cart
                    cart.items = []
                    cart.discount_code = null
                    commit('resetCart', cart)
                    //redirect to response page
                    window.location.href = `/store/checkout/response?payment=success&order=${payload.order_id}`
                    //next: the checkout page will be redirected to thank you page
                } else if(payload.payment_status == 'failed') {
                    commit('setCheckoutError', 'payment error')
                    //redirect to response page
                    window.location.href = `/store/checkout/response?payment=failed&order=${payload.order_id}`
                }
            }
        },

        refreshEverything({commit}, payload){
            commit('clearCheckoutSuccess')
            commit('clearCheckoutError')
            commit('checkoutProcessing', false)
            commit('setCheckoutBtnProcessing', false)
            commit('setCheckoutOverlay', false)
        }
    }
}