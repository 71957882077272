<template>
    <div id="customer-dashboard" v-if="customer">
        <div class="row">
            <div class="col-md-9">
                <div v-if="section == 'main'">
                    <div class="tab-switcher mb-2">
                        <span class="tab-btn" :class="{ 'is-active' : currentTab == 'online-store' }" @click="currentTab = 'online-store'">Online Store</span>
                        <span class="tab-btn" :class="{ 'is-active' : currentTab == 'home-nursing' }" @click="currentTab = 'home-nursing'">Home Nursing</span>
                    </div>

                    <div v-show="currentTab == 'online-store'">
                        <h5>Account Overview</h5>
                        <div class="row pt-3">
                            <div class="col-md-4">
                                <div class="overview-box">
                                    <i class="fas fa-coins"></i>
                                    <div>
                                        <span class="d-block">TOTAL SPEND</span>
                                        <span class="d-block box-value">RM{{ getNumberWithCommas(getTotalSpend()) }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mt-md-0 mt-2">
                                <div class="overview-box">
                                    <i class="fab fa-dropbox"></i>
                                    <div>
                                        <span class="d-block">TOTAL ORDER</span>
                                        <span class="d-block box-value">{{ getTotalOrder() }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mt-md-0 mt-2">
                                <div class="overview-box">
                                    <i class="fas fa-star"></i>
                                    <div>
                                        <span class="d-block">POINTS COLLECTED</span>
                                        <span class="d-block box-value">{{ getNumberWithCommas(getTotalPoint()) }} pts</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5 mx-0" style="background-color: #F4F6F9">
                            <div class="col-4 col-md">
                                <div class="order-status-box px-3 py-4 text-center">
                                    <span class="text-center box-value" v-if="getTotalOrderToPay() > 0">{{ getTotalOrderToPay() }}</span>
                                    <!-- <i class="fa fa-clock d-block mb-2"></i> -->
                                    <img src="@/assets/icons/money.svg" class="mb-2" />
                                    <span class="d-block text-center small">To Pay</span>
                                </div>
                            </div>
                            <div class="col-4 col-md">
                                <div class="order-status-box px-3 py-4 text-center">
                                    <div
                                        class="text-center box-value"
                                        v-if="getTotalOrderToShip() > 0">
                                            {{ getTotalOrderToShip() }}
                                    </div>
                                    <!-- <i class="fa fa-clock d-block mb-2"></i> -->
                                    <img src="@/assets/icons/box.svg" class="mb-2" />
                                    <span class="d-block text-center small">To Ship</span>
                                </div>
                            </div>
                            <div class="col-4 col-md">
                                <div class="order-status-box px-3 py-4 text-center">
                                    <div
                                        class="text-center box-value"
                                        v-if="getTotalOrderToReceive() > 0">
                                            {{ getTotalOrderToReceive() }}
                                    </div>
                                    <!-- <i class="fa fa-clock d-block mb-2"></i> -->
                                    <img src="@/assets/icons/truck.svg" class="mb-2" />
                                    <span class="d-block text-center small">To Receive</span>
                                </div>
                            </div>
                            <div class="col-4 col-md">
                                <div class="order-status-box px-3 py-4 text-center">
                                    <div
                                        class="text-center box-value"
                                        v-if="getTotalOrderCompleted() > 0">
                                            {{ getTotalOrderCompleted() }}
                                    </div>
                                    <!-- <i class="fa fa-truck mb-2"></i> -->
                                    <img src="@/assets/icons/check-circle.svg" class="mb-2" />
                                    <span class="d-block text-center small">Completed</span>
                                </div>
                            </div>
                            <div class="col-4 col-md">
                                <div class="order-status-box px-3 py-4 text-center">
                                    <div
                                        class="text-center box-value"
                                        v-if="getTotalOrderCancelled() > 0">
                                            {{ getTotalOrderCancelled() }}
                                    </div>
                                    <!-- <i class="fa fa-clock d-block mb-2"></i> -->
                                    <img src="@/assets/icons/calendar-times.svg" class="mb-2" />
                                    <span class="d-block text-center small">Cancelled</span>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5">
                            <h5>Order History</h5>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Order ID</th>
                                            <th>Date</th>
                                            <th>Payment Status</th>
                                            <th>Delivery Status</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(order, ind) in customer.orders" :key="order.id">
                                            <td>{{ ind + 1 }}</td>
                                            <td><router-link :to="`/customer/orders/${order.id}`"><u>#{{ order.name }}</u></router-link></td>
                                            <td>{{ getDateOnly(order.updatedAt) }}</td>
                                            <td>
                                                <span
                                                    class="badge badge-pill font-weight-normal px-3 py-2 mb-0"
                                                    :class="getPaymentStatus(order.financial_status) == 'Paid' ? 'badge-success text-light' : 'badge-light text-muted'">
                                                        {{ getPaymentStatus(order.financial_status) }}
                                                </span>
                                            </td>
                                            <td>{{ getDeliveryStatus(order.fulfillment_status) }}</td>
                                            <td>RM{{ order.total_price }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row mt-5 mx-0">
                            <div class="col-md-6">
                                <div class="chart-box">
                                    <canvas id="myChart01" style="height:150px;width:240px;"></canvas>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="chart-box">
                                    <canvas id="myChart02" style="height:150px;width:240px;"></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="currentTab == 'home-nursing'">
                        <booking-list-comp />
                    </div>
                </div>
                <div v-else-if="section == 'wishlist'">
                    <wishlist-comp />
                </div>
            </div>
            <!-- <div class="col-md-3 order-0 order-md-1"> -->
            <div class="col-md-3">
                <h5 class="text-nowrap">Account Details</h5>
                <div id="account-detail">
                    <p class="font-weight-bold">{{ customer.user.username }}</p>
                    <p v-if="customer.billing_address">
                        {{ verifyAddressItem(customer.billing_address.company)? verifyAddressItem(customer.billing_address.company) + ',' : null }} <br v-if="verifyAddressItem(customer.billing_address.company)" />
                        {{ verifyAddressItem(customer.billing_address.address1)? verifyAddressItem(customer.billing_address.address1) + ',' : null }} <br v-if="verifyAddressItem(customer.billing_address.address1)" />
                        {{ verifyAddressItem(customer.billing_address.address2)? verifyAddressItem(customer.billing_address.address2) + ',' : null}} <br v-if="verifyAddressItem(customer.billing_address.address2)" />
                        {{ customer.billing_address.city }}, {{ customer.billing_address.zipcode }} <br />
                        {{ customer.billing_address.province }} {{ customer.billing_address.country }} <br />
                        {{ customer.billing_address.phone }}
                    </p>
                    <!-- <p v-else class="font-italic">
                        Please click here to create billing address.
                    </p> -->
                    <span class="d-block"><router-link class="text-primary" to="/customer">Account Overview</router-link></span>
                    <span class="d-block"><router-link class="text-primary" to="/customer/dashboard?section=wishlist">My Wishlists</router-link></span>
                    <span class="d-block"><router-link class="text-primary" to="/customer/addresses">Manage Addresses</router-link></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import API from './../../utils/API'
import moment from 'moment'
import Chart from 'chart.js'

import BookingListComp from './BookingList'
import WishlistComp from './Wishlist'

export default {
    components: {
        BookingListComp,
        WishlistComp
    },
    data(){
        return {
            section: 'main',
            customer: null,
            currentTab: 'online-store',
            bookingForms: null,
            bookingFormsAssignedProvider: []
        }
    },
    watch: {
        $route(to, from){
            // console.log('query : ', to.query.section)
            if(to.query.section && (to.query.section != from.query.section)) this.section = to.query.section
            else this.section = 'main'
        }
    },
    computed: {
        ...mapGetters(['currentCustomer']),
    },
    methods: {
        initChart(){
            //Setting data
            let orders = this.customer.orders.reverse()
            let totalOrderData = []
            let totalSpendData = []

            //calculate the order based on day
            let ind = 0
            let date = null
            for (let i = 0; i < orders.length; i++) {
                if(date && date == moment(orders[i].createdAt).format('MM/D/YYYY')) {
                    totalOrderData[ind - 1].data = totalOrderData[ind - 1].data + 1
                    totalSpendData[ind - 1].data = totalSpendData[ind - 1].data + parseFloat(orders[i].total_price)
                }
                else {
                    totalOrderData.push({
                        date: moment(orders[i].createdAt).format('MM/D/YYYY'),
                        data: 1
                    })
                    totalSpendData.push({
                        date: moment(orders[i].createdAt).format('MM/D/YYYY'),
                        data: parseFloat(orders[i].total_price)
                    })
                    ind++
                    date = moment(orders[i].createdAt).format('MM/D/YYYY')
                }
            }

            //init chart attrs
            var labels = []
            var totalOrderChartData = []
            var totalSpendChartData = []
            for (var i = 0; i < totalOrderData.length; i++) {
                labels.push(totalOrderData[i].date)
                totalOrderChartData.push(totalOrderData[i].data)
                totalSpendChartData.push(totalSpendData[i].data)
            }

            //init chart
            var ctx1 = document.getElementById('myChart01')
            var ctx2 = document.getElementById('myChart02')
            var myChart = new Chart(ctx1, {
                type: 'line',
                data: {
                    labels : labels,
                    datasets : [
                    {
                        label: 'Total Orders',
                        data : totalOrderChartData,
                        borderWidth: 2
                    }]
                },
                options: {
                    plugins: {
                        filler: {
                            propagate: true
                        }
                    }
                }
            })
            var myChart = new Chart(ctx2, {
                type: 'line',
                data: {
                    labels : labels,
                    datasets : [
                    {
                        label: 'Total Spend (RM)',
                        data : totalSpendChartData,
                        borderWidth: 2
                    }]
                },
                options: {
                    plugins: {
                        filler: {
                            propagate: true
                        }
                    }
                }
            })
        },
        verifyAddressItem(_item){
            if(_item != null && _item != '-' && _item != '' && _item != ' ') return _item
            else return null
        },
        getDateOnly(_strDate){
            return moment(_strDate, 'YYYYMMDD').format('LL')
        },
        getPaymentStatus(_val){
            switch (_val) {
                case 'pending':
                    return 'Pending';
                case 'not_paid':
                    return 'Not Paid';
                case 'paid':
                    return 'Paid';
            
                default:
                    'Pending';
            }
        },
        getDeliveryStatus(_val){
            switch (_val) {
                case 'pending':
                    return 'Pending';
                case 'unfulfilled':
                    return 'In Progress';
                case 'fulfilled':
                    return 'Delivered';
            
                default:
                    'Pending';
            }
        },
        getNumberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        getTotalOrder(){
            return this.customer.orders.length
        },
        getTotalSpend(){
            let total = 0.00
            this.customer.orders.forEach(order => {
                total += order.total_price
            })
            return total.toFixed(2)
        },
        getTotalPoint(){
            let totalSpend = parseFloat(this.getTotalSpend())
            let totalPoint = Math.round(totalSpend / 2)
            return totalPoint
        },
        getTotalOrderCompleted(){
            let total  = 0
            this.customer.orders.forEach(order => {
                if(order.fulfillment_status == 'fulfilled') total++
            })
            return total
        },
        getTotalOrderToReceive(){
            let total  = 0
            this.customer.orders.forEach(order => {
                if(order.fulfillment_status == 'unfulfilled' && order.financial_status == 'paid' && order.tracking_no) total++
            })
            return total
        },
        getTotalOrderToPay(){
            let total  = 0
            this.customer.orders.forEach(order => {
                if(order.fulfillment_status == 'pending' && ['not_paid', 'pending'].includes(order.financial_status)) total++
            })
            return total
        },
        getTotalOrderToShip(){
            let total  = 0
            this.customer.orders.forEach(order => {
                if(order.fulfillment_status == 'unfulfilled' && order.financial_status == 'paid' && !order.tracking_no) total++
            })
            return total
        },
        getTotalOrderCancelled(){
            let total  = 0
            this.customer.orders.forEach(order => {
                if(order.cancelled_at) total++
            })
            return total
        },
        // async getBookingForms(){
        //     let res = await API.get(`/booking-forms?customer=${this.currentCustomer.user.customer.id}`)
        //     this.bookingForms = res.data
        // },
        // async getAssignedProvider(){
        //     for (let ind = 0; ind < this.bookingForms.length; ind++) {
        //         if(this.bookingForms[ind].provider_booking_jobs.length > 0){
        //             let assignedJob = this.bookingForms[ind].provider_booking_jobs.find(job => job.type_of_job == 'assigned')
        //             if(assignedJob) {
        //                 let resProvider = await API.get(`/provider-booking-jobs/${assignedJob.id}`)
        //                 this.bookingFormsAssignedProvider.push(resProvider.data.provider)
        //             } else this.bookingFormsAssignedProvider.push(null)
        //         } else this.bookingFormsAssignedProvider.push(null)
        //     }
        // },
        // getCategoryName(_category){
        //     switch (_category) {
        //         case 'nurse':
        //             return 'Nurse'
        //         case 'physio':
        //             return 'Physio'
        //         case 'medical_assistant':
        //             return 'Medical Assistant'
        //         case 'care_aide':
        //             return 'Care Aide'

        //         default:
        //             break;
        //     }
        // },
        // getBookingDate(_startDateTime){
        //     return moment(_startDateTime).format('dddd, D MMMM YYYY')
        // },
        // getBookingTime(_startDateTime, _endDateTime){
        //     return `${moment(_startDateTime).format('LT')} - ${moment(_endDateTime).format('LT')}`
        // }
        // verifyAddressAttribute(_attr){
        //     if(_attr && _attr != ''  && _attr != '-'  && _attr != ' ')
        //         return true
        //     else
        //      return false
        // }
    },
    async mounted(){
        if(this.$route.query.maintab && this.$route.query.maintab == 'home_nursing')
            this.currentTab = 'home-nursing'
        // await this.getBookingForms()
        // await this.getAssignedProvider()
        if(this.$route.query.section) this.section = this.$route.query.section

        let resCustomer = await API.get(`/customers/${this.currentCustomer.user.customer.id}`)
        this.customer = resCustomer.data

        // console.log(this.customer.orders);

        setTimeout(() => this.initChart(), 500) //wait for html to be fully loaded
    }
}
</script>

<style scoped>
#customer-dashboard {
    font-size: 15px;
}
#customer-dashboard h1, h2, h3, h4, h5 {
    line-height: 1.4;
    margin: 0 0 20px;
    /*font-family: "Roboto Slab",serif;*/
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
}

.chart-box {
    /* border: 1px solid #9d9d9d; */
    /* padding: 25px 10px; */
    padding: 25px 0px;
}
.overview-box {
    /* border: 1px solid #9d9d9d; */
    padding: 15px 20px;
    background: rgba(169, 26, 24, 0.9);
    border-radius: 5px;
    color: #FFFFFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1.3;
}

.table th {
    color: #7CC6D6;
    font-weight: 500;
    border-top: none;
    border-bottom: none;
}

.table td {
    font-weight: 300;
    padding: 0.75rem 1rem;
}

.table,
#account-detail {
    /* font-family: "Karla",arial,sans-serif; */
    /* font-size: 15px; */
    font-size: 14px;
    line-height: 30px;
    color: #333;
}
#customer-dashboard a {
    color: #333;
}
#customer-dashboard a.text-primary {
    color: #a91a18!important;
}
#customer-dashboard a.text-primary:hover {
    color: #cc2c2a!important;
    text-decoration: underline;
}
#customer-dashboard a:hover {
    color: #000;
    text-decoration: unset;
}

.overview-box i {
    font-size: 26px;
    margin-right: 1rem;
}
.overview-box .box-value {
    font-size: 18px;
    font-weight: bold;
    /*font-family: "Roboto Slab",serif;*/
}

.order-status-box .box-value {
    color: #FFFFFF;
    font-size: 0.8rem;
    width: 20px;
    height: 20px;
    background-color: #DA1414;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: calc(50% - 2.25rem);
}

.tab-switcher {
    height: 50px;
}
.tab-btn {
    padding: 7px 20px;
    cursor: pointer;
    border-bottom: 3px solid #DADEE3;
    border-radius: 0px;
    color: #A5ABB3;
    font-weight: 500;
}
.tab-btn.is-active {
    border-bottom: 3px solid #A91A18;
    border-radius: 0px;
    color: #A91A18;
    font-weight: 500;
}

.card.custom-card {
    border-radius: 9px;
    border: 1px solid #EBEEF2;
    font-size: 14px;
    /* cursor: pointer; */
}
.card.custom-card .btn {
    background-color: #7CC6D6;
    border-color: #7CC6D6;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px 20px;
}
.card.custom-card a:hover {
    text-decoration: underline!important;;
}

.badge.custom-badge {
    padding: 7px 18px;
    background-color: rgba(169, 26, 24, 0.5);
    font-weight: 400;
    color: #fff;
}


@media (min-width: 768px) {
    .chart-box.neg-mr-20,
    .overview-box.neg-mr-20 {
        margin-right: -20px;
    }
}
</style>