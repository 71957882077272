<template>
    <div class="main-body">
        <div class="row">
            <!-- <div class="col-md-6">
                <div class="img text-center">
                    <img src="/img/logo_full.png" alt="" style="width:70%;">
                </div>
            </div> -->
            <!-- <div class="col-md-6"> -->
            <div class="col-lg-6 offset-lg-3">
                <div v-if="authError" class="alert alert-danger text-center" role="alert">
                    <i class="fas fa-times-circle"></i><span> Sorry. {{ authError }}</span>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Login</h5>
                        
                        <div class="form-group">
                            <input class="form-control" type="email" placeholder="Email" v-model="email">
                        </div>
                        <div class="form-group">
                            <input class="form-control" type="password" placeholder="Password" v-model="password">
                        </div>
                        <button v-if="!authProcessing" class="btn btn-red" @click="submitAuth">Login</button>
                        <button v-else class="btn btn-red" @click="submitAuth" disabled>
                            <span class="spinner-border spinner-border-sm"></span>
                        </button>

                        <div class="other-info mt-2">
                            <span>
                                <router-link to="/auth/resetpassword">Forgot Password</router-link>
                            </span>
                        </div>

                        <div class="wrap"><p class="centre-line"><span>Or</span></p></div>

                        <div class="row mt-2">
                            <div class="col-md-12">
                                <button class="btn btn-facebook" :onclick="authViaFacebook()">Facebook</button>
                            </div>
                            <!-- <div class="col-md-12 mt-3">
                                <button class="btn btn-google">Google</button>
                            </div> -->
                        </div>

                        <div class="text-center mt-4">
                            <div class="redirectto">
                                <span>
                                    New to Selcare? <router-link to="/auth/signup">Sign Up</router-link>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from './../../utils/API'
import { mapActions, mapGetters } from 'vuex'

export default {
    data(){
        return {
            username: null,
            email: null,
            password: null
        }
    },
    computed: {
        ...mapGetters(['authProcessing', 'authError']),
    },
    methods: {
        ...mapActions(['login']),
        submitAuth(){
            let redirectto = this.$route.query.redirect ? this.$route.query.redirect : null

            this.login({
                email: this.email,
                password: this.password,
                redirectto: redirectto
            })
        },
        authViaFacebook(){
            let url =`${API.getAPIUrl()}/connect/facebook`
            return `location.href='${API.getAPIUrl()}/connect/facebook'`
        }
    },
    mounted(){

    }
}
</script>

<style scoped>
.main-body {
    padding: 60px 0px;
    /*font-family: "Karla",serif;*/
}

.img {
    padding: 130px 0px;
}

.card {
    border-radius: 0px;
    border: none;
}
.card .card-body {
    margin: 10px 0px;
}
.card .card-title {
    margin-bottom: 30px;
}
.card .card-body .form-group {
    margin-bottom: 25px;
}
.card .card-body input {
    border-radius: 0px;
}
.card .card-body button {
    width: 100%;
    border-radius: 0px;
    border: none;
}
.card .card-body .btn-red{
    background-color: #a91a18;
    color: #fff;
}
.card .card-body .btn-facebook{
    background-color: #1877f2;
    color: #fff;
    font-weight: bold;
}
.card .card-body .btn-google{
    background-color: #4285f4;
    color: #fff;
    font-weight: bold;
}
.card .card-body p {
    font-size: 14px;
}
.card .card-body span {
    font-size: 14px;
}
.card .card-body a {
    color: #a91a18;
}
/* .card .card-body .other-info {

} */

/** Centre line */
.wrap {
    margin: 0px 0px;
    position: relative;
    height: 50px;
}

p.centre-line {
    text-align:center;
    position: absolute;
    top:50%;
    left:50%;
    width:100%;
    transform:translate(-50%, -50%);
}
p.centre-line:before {
    content:"";
    position: absolute;
    width: 100%;
    height: 1.5px;
    top:50%;
    left:0;
    z-index:-1;
    background: #dbdbdb;
}
p.centre-line span {
    background-color:#ffffff;
    color: #dbdbdb;
    padding:1rem;
    display: inline-block;
    text-transform: uppercase;
}
</style>