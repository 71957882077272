<template>
    <div class="sticky-top bg-white" style="z-index: 999999">
        <!-- <top-line-header-comp /> -->
        <!-- Inititate Desktop Nav -->
        <!-- Top Nav -->
        <nav id="top-nav" class="navbar navbar-expand-lg navbar-light">
            <div class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                        <a class="nav-link" href="#">English</a>
                    </li>
                    <!-- <li class="nav-item active">
                        <router-link class="nav-link text-cap" to="/home-nursing/apply">Home Nursing</router-link>
                    </li> -->
                </ul>
            </div>
            <div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
                <ul class="navbar-nav ml-auto">
                    <li v-if="!currentCustomer" class="nav-item">
                        <router-link to="/auth/login" class="nav-link">Log In</router-link>
                    </li>
                    <li v-if="!currentCustomer" class="nav-item">
                        <router-link to="/auth/signup" class="nav-link">Sign Up</router-link>
                    </li>
                    <li v-if="currentCustomer" class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="notiDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="far fa-bell"></i>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="notiDropdown">
                            <a v-if="notifications.length == 0" class="dropdown-item disabled" href="#">There is no notification yet.</a>
                            <a v-else v-for="notification in notifications" :key="notification.id" class="dropdown-item disabled" href="#">{{ notification.content }}</a>
                        </div>
                    </li>
                    <li v-if="currentCustomer" class="nav-item">
                        <router-link to="/customer/dashboard" class="nav-link">{{ currentCustomer.user.username }}</router-link>
                    </li>
                    <li v-if="currentCustomer" class="nav-item">
                        <a href="#" class="nav-link" @click="signOut">Log Out</a>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/store/cart">My Cart</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/store/cart">
                            {{ numberOfItems }}
                            <i class="fas fa-shopping-cart ml-2" />
                        </router-link>
                    </li>
                </ul>
            </div>
        </nav>
        <!-- Bottom Nav -->
        <nav id="bottom-nav" class="navbar navbar-expand-lg navbar-light">
            <div class="navbar-left navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
                <div class="navbar-brand mr-auto">
                    <router-link to="/" class="">
                        <img src="/img/logo_selcare_512.png" height="45" alt="" loading="lazy">
                    </router-link>
                    <!-- <router-link class="text-after-logo text-muted" to="/store/home-nursing/apply">Home Nursing</router-link> -->
                </div>
            </div>
            <div class="navbar-collapse collapse mx-auto order-0">
                <ul class="navbar-nav mx-auto">
                    <li class="nav-item" :class="{'active' : $route.path == '/'}">
                        <router-link class="nav-link" to="/">Home</router-link>
                    </li>
                    <li class="nav-item dropdown mr-1" :class="{'active' : $route.path == '/store'}">
                        <router-link to="/store" class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Pharmacy</router-link>
                        <div class="dropdown-menu mt-0 pt-1 pb-1" :class="{'active' : $route.path.includes('/store/products')}">
                            <router-link to="/store/products" class="dropdown-item">All Products</router-link>
                            
                            <router-link to="/store/products?filter=new_arrival" class="dropdown-item">New Arrivals</router-link>
                            <router-link to="/store/products?filter=promotion" class="dropdown-item">Promotions &amp; Sales</router-link>
                            <!-- <router-link to="/store/products" class="dropdown-item">Member Benefits</router-link> -->
                        </div>
                    </li>
                    <li class="nav-item mr-1">
                        <router-link class="nav-link" to="/home-nursing/booking">Home Nursing</router-link>
                    </li>
                    <li class="nav-item" :class="{'active' : $route.path.includes('/info/about-us')}">
                        <router-link class="nav-link" to="/info/about-us">About</router-link>
                    </li>
                    <!-- <li class="nav-item badge-pink" :class="{'active' : $route.path.includes('/store/home-nursing/apply')}">
                        <router-link class="nav-link" to="/store/home-nursing/apply">Home Nursing</router-link>
                    </li> -->
                    <li class="nav-item">
                        <a class="nav-link" href="https://selgatecorporation.com/contact-us/">Contact Us</a>
                    </li>
                </ul>
            </div>
            <div class="navbar-right navbar-collapse collapse w-100 order-3 dual-collapse2">
                <div class="navbar-nav ml-auto">
                    <!-- <form class="form-inline">
                        <input class="form-control mr-sm-2" @keyup.50="submitSearch" type="search" placeholder="Search our store" aria-label="Search our store">
                    </form> -->
                    <div class="form-inline">
                        <input class="form-control mr-sm-2" v-model="keywords" @keyup.enter="submitSearch" type="search" placeholder="Search our store" aria-label="Search our store">
                    </div>
                </div>
            </div>
        </nav>


        <!-- Inititate Mobile Nav -->
        <nav id="mobile-nav" class="navbar navbar-light light-blue lighten-4">
            <!-- Navbar brand -->
            <div class="navbar-brand mr-auto">
                <router-link to="/store" data-toggle="collapse" data-target="#navbarSupportedContent1">
                    <img src="/img/logo_selcare_512.png" height="45" alt="" loading="lazy">
                </router-link>
                <!-- <router-link class="text-after-logo text-muted" to="/home-nursing/apply" data-toggle="collapse" data-target="#navbarSupportedContent1">Home Nursing</router-link> -->
            </div>
            <!-- Collapse button -->
            <button class="navbar-toggler toggler-example ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1"
                aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation"><span class="dark-blue-text"><i
                    class="fas fa-bars fa-1x"></i></span></button>
            <!-- Cart button -->
            <div class="nav-item border-left">
                <router-link class="nav-link pr-0" to="/store/cart" style="color: rgba(0,0,0,.5)">
                    {{ numberOfItems }}
                    <i class="fas fa-shopping-cart ml-2" />
                </router-link>
            </div>
            <!-- Collapsible content -->
            <div class="collapse navbar-collapse" id="navbarSupportedContent1" style="max-height: calc(100vh - 65px); overflow: auto">
                <!-- Links -->
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item" :class="{'active' : $route.path == '/'}">
                        <router-link class="nav-link" data-toggle="collapse" data-target="#navbarSupportedContent1" to="/store">Home</router-link>
                    </li>
                    <li class="nav-item" :class="{'active' : $route.path.includes('/products')}">
                        <router-link class="nav-link" data-toggle="collapse" data-target="#navbarSupportedContent1" to="/store/products">Products</router-link>
                    </li>
                    <li class="nav-item" :class="{'active' : $route.path.includes('/home-nursing/apply')}">
                        <router-link class="nav-link" data-toggle="collapse" data-target="#navbarSupportedContent1" to="/home-nursing/apply">Home Nursing</router-link>
                    </li>
                    <li class="nav-item" :class="{'active' : $route.path.includes('/info/about-us')}">
                        <router-link class="nav-link" data-toggle="collapse" data-target="#navbarSupportedContent1" to="/info/about-us">About</router-link>
                    </li>
                    <li v-if="!currentCustomer" class="nav-item" :class="{'active' : $route.path.includes('/auth/login')}">
                        <router-link class="nav-link" data-toggle="collapse" data-target="#navbarSupportedContent1" to="/auth/login">Login</router-link>
                    </li>
                    <li v-if="!currentCustomer" class="nav-item" :class="{'active' : $route.path.includes('/auth/signup')}">
                        <router-link class="nav-link" data-toggle="collapse" data-target="#navbarSupportedContent1" to="/auth/signup">Register</router-link>
                    </li>
                    <li v-if="currentCustomer" class="nav-item" :class="{'active' : $route.path.includes('/auth/login')}">
                        <router-link class="nav-link" data-toggle="collapse" data-target="#navbarSupportedContent1" to="/customer/dashboard">{{ currentCustomer.user.username }}</router-link>
                    </li>
                    <li v-if="currentCustomer" class="nav-item" :class="{'active' : $route.path.includes('/auth/signup')}">
                        <a href="#" class="nav-link" data-toggle="collapse" data-target="#navbarSupportedContent1" @click="signOut">Log Out</a>
                    </li>
                    <li class="nav-item" :class="{'active' : $route.path.includes('/store/cart')}">
                        <router-link class="nav-link" data-toggle="collapse" data-target="#navbarSupportedContent1" to="/store/cart">My Cart</router-link>
                    </li>
                    <li class="nav-item">
                        <div class="form-inline mr-3">
                            <input class="form-control mr-sm-2" v-model="keywords" @keyup.enter="submitSearch" type="search" placeholder="Search our store" aria-label="Search our store">
                        </div>
                    </li>
                </ul>
                <!-- Links -->

            </div>
            <!-- Collapsible content -->
        </nav>
    </div>
</template>

<script>
import API from './../utils/API'
import { mapGetters, mapActions } from 'vuex'

import TopLineHeaderComp from './TopLineHeader'
export default {
    components: {
        TopLineHeaderComp
    },
    data(){
        return {
            keywords: null,
            notifications: []
        }
    },
    computed: {
        ...mapGetters(['numberOfItems', 'currentCustomer']),
        // numberOfItems(){
        //     return this.$store.getters.numberOfItems
        // }
    },
    methods: {
        ...mapActions(['signOut']),
        submitSearch(){
            window.location.href = `/store/products?keywords=${this.keywords}`
            // this.$router.push(`/products?keywords=${this.keywords}`)
        },
    },
    async mounted(){
        // console.log('customer : ', this.currentCustomer)

        //get notification if currentcustomer
        if(this.currentCustomer){
            let notiRes = await API.get('/notifications')
            this.notifications = notiRes.data
        }

        $('.navbar-collapse').on('show.bs.collapse', function () {
            $('body').addClass('navbar-collapsed')
        })

        $('.navbar-collapse').on('hidden.bs.collapse', function () {
            $('body').removeClass('navbar-collapsed')
        })
    }
}
</script>

<style>
/** General */
nav {
    /* font-family: "Karla",arial,sans-serif; */
    text-transform: uppercase;
    color: #666;
    letter-spacing: 0.12em;
    font-weight: bold;
    font-size: 14px;
}
nav .form-inline input[type=search] {
    width: 250px;
}
nav .form-inline input:focus {
    border-color: #999;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 0, 0, 0.075);
    outline: 0 none;
}
nav .nav-item.dropdown .dropdown-menu {
    text-transform: initial;
    margin-top: 9px;
}

nav .text-after-logo {
    font-size: 14px;
    margin-left: 20px;
    text-decoration: unset;
    font-weight: bold;
}

nav#bottom-nav .order-0 {
    min-width: 550px;
}
nav#bottom-nav .nav-item.badge-pink {
    border: 1px solid #F3CACA;
    border-radius: 11px;
    background-color: #F3CACA;
    padding: 0px 5px;
    margin-left: 5px;
}

/** For Desktop Size Nav */
@media (min-width: 992px) {
    /** Top Nav */
    nav#top-nav {
        border-bottom: 1px solid rgb(165 157 157 / 16%);
    }
    nav#top-nav ul li.nav-item {
        padding: 0px 10px;
    }
    nav#top-nav ul li.nav-item:not(:last-child) {
        border-right: 1px solid rgb(165 157 157 / 16%);
    }

    /** Bottom Nav */
    nav#bottom-nav {
        border-bottom: 2px solid rgb(165 157 157 / 15%);
    }
    nav#bottom-nav .navbar-left {
        padding-left: 55px;
    }
    nav#bottom-nav .navbar-right {
        padding-right: 55px;
    }

    /** Undisplay Mobile Nav */
    nav#mobile-nav {
        display: none;
    }
}

/** For Mobile Size Nav */
@media (max-width: 991px) {
    /** Mobile Nav */
    nav#mobile-nav {
        display: flex;
        border-bottom: 1px solid rgb(165 157 157 / 16%);
        max-height: 100vh;
        overflow: hidden;
    }
    nav#mobile-nav button {
        margin-top: 10px;
        /* margin-right: 0px; */
        margin-bottom: 10px;
        /* margin-left: 0px; */
        border: none;
        color: #000;
    }
    nav#mobile-nav #navbarSupportedContent1 {
        margin-left: -17px;
        margin-right: -26px;
        max-height: calc(100vh - 1rem - 50px);
        overflow-y: auto;
    }
    nav#mobile-nav #navbarSupportedContent1 li.nav-item {
        padding: 8px 15px;
        border-top: 1px solid #e5e5e5;
    }
    nav#mobile-nav #navbarSupportedContent1 li.nav-item:last-child {
        padding-top: 15px;
    }
    nav#mobile-nav #navbarSupportedContent1 li.nav-item .form-inline input[type=search] {
        width: 100%;
    }

    /** Undisplay Top Nav */
    nav#top-nav {
        display: none;
    }
    /** Undisplay Bottom Nav */
    nav#bottom-nav {
        display: none;
    }

    nav .text-after-logo {
        font-size: 12px;
        margin-left: 10px;
    }

    body.navbar-collapsed {
        overflow: hidden;
    }
}

.text-cap {
    text-transform: capitalize;
}
</style>