<template>
    <div id="header-v2" class="mb-3">
        <!-- <top-line-header-comp /> -->
        <div class="container" v-if="!showonly">
            <nav class="navbar navbar-expand-lg navbar-light d-md-flex d-sm-non d-none">
                <div class="navbar-left navbar-collapse collapse order-1 order-md-0 dual-collapse2">
                    <div class="navbar-brand mr-auto">
                        <router-link to="/" class="">
                            <img src="/img/logo_selcare_small.png" height="45" alt="" loading="lazy">
                        </router-link>
                    </div>
                </div>
                <div class="navbar-collapse collapse mx-auto order-0">
                    <ul class="navbar-nav mx-auto">
                        <li class="nav-item mr-1" :class="{'active' : $route.path == '/'}">
                            <router-link to="/" class="nav-link">Home</router-link>
                        </li>
                        <li class="nav-item dropdown mr-1">
                            <router-link to="/store" class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Pharmacy</router-link>
                            <div class="dropdown-menu mt-0 pt-1 pb-1">
                                <router-link to="/store/products" class="dropdown-item">Products</router-link>
                            </div>
                        </li>
                        <li class="nav-item mr-1" :class="{'active' : $route.path == '/home-nursing/booking'}">
                            <router-link class="nav-link" to="/home-nursing/booking">Home Nursing</router-link>
                        </li>
                        <!-- <li class="nav-item badge-blue">
                            <router-link class="nav-link" to="/store/home-nursing/apply">Become a Home Nursing</router-link>
                        </li> -->
                        <li class="nav-item dropdown mr-1">
                            <!-- <router-link class="nav-link" to="/info/about-us">About</router-link> -->
                            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="https://selgatecorporation.com/healthcare/">About Us</a>
                            <div class="dropdown-menu mt-0 pt-1 pb-1">
                                <router-link to="/info/about-nursing" class="dropdown-item">About Nursing</router-link>
                            </div>
                        </li>
                        <li class="nav-item">
                            <!-- <router-link class="nav-link" to="/feedback">Contact Us</router-link> -->
                            <a class="nav-link" href="https://selgatecorporation.com/contact-us/">Contact Us</a>
                        </li>
                    </ul>
                </div>
                <div class="navbar-right navbar-collapse collapse order-3 dual-collapse2">
                    <div class="navbar-nav ml-auto">
                        <div class="btn-group">
                            <span class="btn dropdown-toggle" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false"><i class="fas fa-user-circle"></i></span>
                            <div v-if="currentCustomer" class="dropdown-menu dropdown-menu-right">
                                <button class="dropdown-item" type="button" @click="$router.push('/customer/dashboard')">My Dashboard</button>
                                <button class="dropdown-item" type="button" @click="signOut">Logout</button>
                            </div>
                            <div v-else class="dropdown-menu dropdown-menu-right">
                                <button class="dropdown-item" type="button" @click="$router.push('/auth/login')">Log In</button>
                                <button class="dropdown-item" type="button" @click="$router.push('/auth/signup')">Sign Up</button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <!-- Inititate Mobile Nav -->
            <nav id="mobile-nav-header-v2" class="navbar navbar-light light-blue lighten-4 d-md-none d-sm-block d-block">
                <!-- Collapse button -->
                <button class="navbar-toggler toggler-example float-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1"
                    aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation"><span class="dark-blue-text"><i
                        class="fas fa-bars fa-1x"></i></span></button>
                <!-- Collapsible content -->
                <div class="collapse navbar-collapse" id="navbarSupportedContent1">
                    <!-- Links -->
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item" :class="{'active' : $route.path == '/'}">
                            <router-link class="nav-link" data-toggle="collapse" data-target="#navbarSupportedContent1" to="/">Home</router-link>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://selgatecorporation.com/healthcare/">About Us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://selgatecorporation.com/contact-us/">Contact Us</a>
                        </li>
                    </ul>
                    <ul class="navbar-nav mr-auto" v-if="currentCustomer">
                        <li class="nav-item">
                            <span class="nav-link" @click="$router.push('/customer/dashboard')">My Dashboard</span>
                        </li>
                        <li class="nav-item">
                            <span class="nav-link" @click="signOut">Logout</span>
                        </li>
                    </ul>
                    <ul class="navbar-nav mr-auto" v-else>
                        <li class="nav-item" :class="{'active' : $route.path == '/'}">
                            <router-link class="nav-link" data-toggle="collapse" data-target="#navbarSupportedContent1" to="/">Home</router-link>
                        </li>
                        <li class="nav-item">
                            <span class="nav-link" @click="$router.push('/auth/login')">Log In</span>
                        </li>
                        <li class="nav-item">
                            <span class="nav-link" @click="$router.push('/auth/signup')">Sign Up</span>
                        </li>
                    </ul>
                    <!-- Links -->

                </div>
                <!-- Collapsible content -->
            </nav>
        </div>
        <div v-else-if="showonly && showonly == 'invoice'" class="pt-5">
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TopLineHeaderComp from './TopLineHeader'

export default {
    props: ['showonly'],
    components: {
        TopLineHeaderComp
    },
    computed: {
        ...mapGetters(['currentCustomer']),
    },
    methods: {
        ...mapActions(['signOut']),
    }
}
</script>

<style scoped>
nav {
    padding: 30px 30px;
    font-family: "Karla",serif;
}
nav span {
    font-size: 20px;
    color: #000000;
    text-transform: initial;
    /* font-weight: bold; */
}
nav span.nav-link {
    font-size: 14px;
    text-transform: uppercase;
}

nav .nav-item.badge-blue {
    border: 1px solid #B5D4F1;
    border-radius: 15px;
    background-color: #B5D4F1;
    padding: 0px 5px;
    margin-left: 5px;
    color: #2C659A;
}
nav .nav-item.badge-blue a {
    color: #2C659A;
}

nav#mobile-nav-header-v2 {
    border-bottom: unset!important;
}
nav#mobile-nav-header-v2 #navbarSupportedContent1 li.nav-item:last-child {
    padding-top: 0px;
    border-bottom: 1px solid rgb(165 157 157 / 16%);
}
#navbarSupportedContent1 {
    margin-top: 50px;
}
</style>