<template>
    <div id="checkout" v-if="orderData && isParamsValid == 1">
        <div class="custom-container">
            <div class="row">
                <div class="col-md-7">
                    <div class="main-info">
                        <div v-if="orderData.financial_status == 'paid' && orderData.checkout.payment_status == 'success'" class="mt-4 text-center">
                            <div class="status-symbol text-success">
                                <span><i class="far fa-check-circle"></i></span>
                            </div>
                            <h5 v-if="deliveryType == 'ship'" class="font-weight-bold">Your Parcel is On The Way!</h5>
                            <h5 v-else-if="deliveryType == 'pickup'" class="font-weight-bold">You Can Pick Your Item(s) Now!</h5>
                            <p v-if="deliveryType == 'ship' && deliveryName == 'S'">Thanks for your purchase. We have been informed to Selcare's staff to proceed shipment. It will take about 3-5 business days. Feel free to track your parcel by refering at order status.</p>
                            <p v-if="deliveryType == 'ship' && deliveryName == 'E'">Thanks for your purchase. We have been informed to Selcare's staff to proceed shipment. It will take approximately 24 hours, within Klang Valley and 3-5 business days if not. Feel free to track your parcel by refering at order status.</p>
                            <p v-else-if="deliveryType == 'pickup'">Thanks for your purchase. We have been informed to Selcare's staff for your pickup. Please go to the particular pickup store clinic to pickup your item(s).</p>
                            <p class="font-weight-bold">Order ID : {{ orderData.name }}</p>
                            <router-link to="/store/products" class="btn">Keep Shopping</router-link>
                        </div>
                        <div v-else class="mt-4 text-center">
                            <div class="status-symbol text-danger">
                                <span><i class="far fa-times-circle"></i></span>
                            </div>
                            <h5 class="font-weight-bold">Your Order is Unsuccessful!</h5>
                            <p>There was a problem ocurred at payment processing. Whether you canceled the payment or payment processor itself got a problem. Please contact our staff for more info or try again once more.</p>
                            <router-link to="/store/checkout" class="btn">Try Again</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 full-gray">
                    <aside class="side-info" v-if="orderData.line_item.length > 0">
                        <div class="items-box">
                            <div class="row" v-for="item in orderData.line_item" :key="item.id">
                                <div class="col-sm-2">
                                    <div class="img-product">
                                        <img :src="getImageLink(item.img_url)" alt="">
                                        <div class="quantity">
                                            {{ item.quantity }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 title-product">
                                    <span>{{ item.title }}</span>
                                </div>
                                <div class="col-sm-4 subtotal-product">
                                    <span v-if="!isVIP">RM {{ (item.quantity * item.price).toFixed(2) }}</span>
                                    <span v-else>RM 0.00</span>
                                </div>
                            </div>
                        </div>
                        <div class="calculation-box">
                            <div class="mt-2">
                                <span>Subtotal</span>
                                <span class="float-right amount">RM {{ orderData.subtotal_price.toFixed(2) }}</span>
                            </div>
                            <div class="mt-2">
                                <span>Shipping</span>
                                <span class="float-right amount">RM {{ orderData.total_shipping.toFixed(2) }}</span>
                            </div>
                            <div class="mt-2">
                                <span>Taxes (estimated)</span>
                                <span class="float-right amount">RM {{ orderData.total_tax.toFixed(2) }}</span>
                            </div>
                            <!-- <div class="mt-2">
                                <span>Discount</span>
                                <span class="float-right amount">RM {{ orderData.total_discount.toFixed(2) }}</span>
                            </div> -->
                        </div>
                        <div class="total-box">
                            <div>
                                <span>Total</span>
                                <span class="float-right amount">RM {{ orderData.total_price.toFixed(2) }}</span>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import API from './../../../utils/API'

export default {
    data(){
        return {
            isVIP: false,
            orderData: null,
            isParamsValid: 0, //0 is pending, 1 is valid, -1 is invalid
            deliveryType: null,
            deliveryName: null
        }
    },
    computed: {
        ...mapGetters(['currentCustomer']),
    },
    methods: {
        getImageLink(_url) {
            if(_url.includes('default_no_image')) return _url
            else return API.getAPIUrl() + _url
        },
    },
    async mounted(){
        if(this.$route.query.payment && this.$route.query.order){
            let resOrder = await API.get(`/orders/${this.$route.query.order}`)
            this.orderData = resOrder.data
            this.isParamsValid = 1
            //check deliveryType
            this.deliveryType = this.orderData.delivery_type.type
            this.deliveryName = this.orderData.delivery_type.name == 'Standard (2-3 business day)' ? 'S' : 'E';
        }else {
            return this.isParamsValid = -1
        }

        //Set all price to 0.00 if customer type == vip
        if(this.currentCustomer){
            if(this.currentCustomer.user.customer.cust_type == 'vip') this.isVIP = true
            else this.isVIP = false
        }
    }
}
</script>

<style scoped>
/* #checkout {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
} */
.h5, h5 {
    font-size: 1.1rem;
}
.form-control {
    font-size: 14px;
    padding: 20px 15px;
}

/** Custom Container here
    Need to create media queries also.
 */
.custom-container .main-info {
    max-width: 670px;
    margin-left: auto;
    padding-right: 40px;
}
.custom-container .full-gray {
    background-color: #fafafa;
    /* height: calc(100vh - 144px); */
    height: 100vh;
    box-shadow: 1px 0 0 #e1e1e1 inset;
}
.custom-container .side-info {
    max-width: 400px;
    margin-right: auto;
}

.main-info {
    padding: 15px 20px;
}

.side-info {
    padding: 10px 20px 15px 15px;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif; */
    line-height: 1.3em;
    font-size: 14px;
}

.main-info h5 {
    line-height: 1.5em;
    font-weight: normal;
    color: #333333;
}
.main-info .status-symbol {
    font-size: 80px;
}
.main-info .btn {
    background-color: #a91a18;
    color: #fff;
    max-width: 100%;
}

.side-info .items-box {
    padding: 15px 0px;
}
.side-info .items-box .img-product {
    width: 150%;
    margin-bottom: 15px;
}
.side-info .items-box .img-product .quantity {
    border-radius: 11px;
    background-color: rgba(114,114,114,0.9);
    color: #fff;
    position: absolute;
    width: 20px;
    padding: 1px 7px;
    font-size: 12px;
    font-weight: 500;
    top: -8px;
    right: -16px;
}
.side-info .items-box .img-product img {
    width: 100%;
    border-radius: 5px;
}
.side-info .items-box span {
    color: #323232;
    font-weight: 500;
}
.side-info .items-box .title-product {
    max-height: 48px;
    margin-bottom: auto;
    margin-top: auto;
}
.side-info .items-box .subtotal-product {
    max-height: 25px;
    margin-bottom: auto;
    margin-top: auto;
}
.side-info .discount-box {
    border-top: 1px solid rgba(175,175,175,0.34);
    border-bottom: 1px solid rgba(175,175,175,0.34);
    padding: 15px 0px;
}
.side-info .discount-box .col-sm-9 {
    padding: -1px 10px 0px 0px;
}
.side-info .discount-box .btn {
    background-color: #a91a18;
    color: #fff;
    max-width: 22%;
}
.side-info .calculation-box {
    padding: 15px 0px;
    border-bottom: 1px solid rgba(175,175,175,0.34);
}
.side-info .calculation-box .amount {
    font-weight: 500;
}
.side-info .total-box {
    padding: 15px 0px;
}
.side-info .total-box .amount {
    font-weight: 500;
    font-size: 22px;
}

@media (max-width: 575px){
    .side-info .items-box .img-product {
        width: 100%;
    }
}

/* .side-info::after{
    content: "";
    display: block;
    width: 300%;
    position: absolute;
    top: 0;
    left: -100%;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
    -webkit-box-shadow: 0 -1px 0 #e1e1e1 inset;
    box-shadow: 0 -1px 0 #e1e1e1 inset;
}
@media (min-width: 1000px){
    .side-info::after {
        left: 0;
        background-position: left top;
        -webkit-box-shadow: 1px 0 0 #e1e1e1 inset;
        box-shadow: 1px 0 0 #e1e1e1 inset;
    }
} */
</style>