<template>
    <div>
        <!-- Header -->
        <div id="auth-navbar" class="header">
            <div class="container">
                <nav class="navbar navbar-expand navbar-light">
                    <div class="navbar-left navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
                        <router-link to="/" class="navbar-brand mr-auto">
                            <img src="/img/logo_selcare_512.png" height="45" alt="" loading="lazy">
                        </router-link>
                    </div>
                    <div class="navbar-right navbar-collapse collapse w-100 order-3 dual-collapse2">
                        <div class="navbar-nav ml-auto">
                            <span>Need help?</span>
                        </div>
                    </div>
                </nav>
            </div>
        </div>

        <!-- Body -->
        <div class="body gray-color">
            <div class="container">
                <login-comp v-if="$route.path == '/auth/login'" />
                <signup-comp v-if="$route.path == '/auth/signup'"/>
                <reset-password-comp v-if="$route.path == '/auth/resetpassword'"/>
            </div>
        </div>
        <!-- <div class="body white-color">
            <div class="container">
                any component that need white background
            </div>
        </div> -->

       <!-- Footer -->
       <!-- No footer for reset password components-->
       <footer-comp :topfooter="false" v-if="$route.path != '/auth/resetpassword'" />
    </div>
</template>

<script>
import LoginComp from '../../components/customer-auth/Login'
import SignupComp from '../../components/customer-auth/Signup'
import ResetPasswordComp from '../../components/customer-auth/ResetPassword'
import FooterComp from '../../containers/Footer'

export default {
    components: {
        LoginComp,
        SignupComp,
        ResetPasswordComp,
        FooterComp
    }
}
</script>

<style scoped>
.header {
    padding: 10px 0px;
    /*font-family: "Karla",serif;*/
}
.header span {
    color: #a91a18;
    font-size: 18px;
    text-transform: initial;
    /* font-weight: bold; */
}
.body.gray-color {
    background-color: #f6f6f6;
}
.body.white-color {
    background-color: #ffffff;
}
</style>
