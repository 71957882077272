<template>
    <div class="bg-form">
        <div class="container mb-5">
            <div class="sub-container">
                <div class="head-form mb-4">
                    <div class="top-title">
                        <span>Booking for</span>
                        <h4>{{ category }}</h4>
                    </div>
                    <div class="bottom-info row">
                        <div class="col-4 text-center">
                            <span><i class="fas fa-check-circle"></i> Details</span>
                        </div>
                        <div class="col-4 text-center">
                            <span><i class="fas fa-check-circle"></i> Assessment</span>
                        </div>
                        <div class="col-4 text-center">
                            <span><i class="fas fa-check-circle"></i> Summary</span>
                        </div>
                    </div>
                </div>
                <div class="cards">
                    <div class="card text-center">
                        <div v-if="!isConfirmed">
                            <span class="text-big">Please review and confirm before submission.</span>
                        </div>
                        <div class="row" v-else>
                            <div class="offset-3 col-6">
                                <span class="text-big">Please wait for a moment</span>
                                <div class="mt-3 mb-3">
                                    <svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="64px" height="64px" viewBox="0 0 128 128" xml:space="preserve"><rect x="0" y="0" width="100%" height="100%" fill="#FFFFFF" /><g><circle cx="16" cy="64" r="16" fill="#7cc6d6"/><circle cx="16" cy="64" r="16" fill="#a8d9e4" transform="rotate(45,64,64)"/><circle cx="16" cy="64" r="16" fill="#c8e7ee" transform="rotate(90,64,64)"/><circle cx="16" cy="64" r="16" fill="#e5f4f7" transform="rotate(135,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(180,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(225,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(270,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(315,64,64)"/><animateTransform attributeName="transform" type="rotate" values="0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64" calcMode="discrete" dur="1120ms" repeatCount="indefinite"></animateTransform></g></svg>
                                </div>
                                <p>
                                    Processing submission data..
                                </p>
                            </div>
                        </div>
                        <!-- <div class="row" v-else>
                            <div class="offset-3 col-6">
                                <span class="text-big">Please wait for a moment</span>
                                <div class="mt-3 mb-3">
                                    <svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="64px" height="64px" viewBox="0 0 128 128" xml:space="preserve"><rect x="0" y="0" width="100%" height="100%" fill="#FFFFFF" /><g><circle cx="16" cy="64" r="16" fill="#7cc6d6"/><circle cx="16" cy="64" r="16" fill="#a8d9e4" transform="rotate(45,64,64)"/><circle cx="16" cy="64" r="16" fill="#c8e7ee" transform="rotate(90,64,64)"/><circle cx="16" cy="64" r="16" fill="#e5f4f7" transform="rotate(135,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(180,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(225,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(270,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(315,64,64)"/><animateTransform attributeName="transform" type="rotate" values="0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64" calcMode="discrete" dur="1120ms" repeatCount="indefinite"></animateTransform></g></svg>
                                </div>
                                <p>
                                    Our team will check and confirm on your application. Should there be any quiries, we will get back to you.
                                </p>
                                <span class="font-weight-bold">Booking number : 2837</span>
                            </div>
                        </div> -->
                    </div>
                    <div class="card mt-4">
                        <div class="card-title text-center mt-1">
                            <span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3333 14.6663H2.66667C2.48986 14.6663 2.32029 14.5961 2.19526 14.4711C2.07024 14.3461 2 14.1765 2 13.9997V1.99967C2 1.82286 2.07024 1.65329 2.19526 1.52827C2.32029 1.40325 2.48986 1.33301 2.66667 1.33301H13.3333C13.5101 1.33301 13.6797 1.40325 13.8047 1.52827C13.9298 1.65329 14 1.82286 14 1.99967V13.9997C14 14.1765 13.9298 14.3461 13.8047 14.4711C13.6797 14.5961 13.5101 14.6663 13.3333 14.6663ZM12.6667 13.333V2.66634H3.33333V13.333H12.6667ZM5.33333 5.99967H10.6667V7.33301H5.33333V5.99967ZM5.33333 8.66634H10.6667V9.99967H5.33333V8.66634Z" fill="#394452"/>
                                </svg>
                                Invoice
                            </span>
                        </div>
                        <div class="card-body">
                            <div class="row contents">
                                <div class="col-md-6 col-sm-12 col-12 text-lg-right text-md-right position-relative d-lg-none d-md-none d-sm-block d-block mb-3">
                                    <span class="font-weight-500">{{ invoice.invoice_number }}</span>
                                </div>
                                <div class="col-md-6 col-sm-12 col-12">
                                    <p>
                                        <span class="font-weight-500">{{invoice.address_from.firstname}} {{ invoice.address_from.lastname }}</span> <br />
                                        {{ invoice.address_from.address1 }} <br />
                                        {{ invoice.address_from.address2 }} <br />
                                        {{ invoice.address_from.zipcode }}, {{ invoice.address_from.city }}  <br />
                                        {{ invoice.address_from.province }}
                                    </p>
                                    <div class="d-lg-none d-md-none d-sm-block d-block">
                                        <span class="d-block">{{ invoice.address_from.phone }}</span>
                                        <span class="d-block">{{ invoice.address_from.email }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 col-12 text-lg-right text-md-right position-relative d-md-block d-sm-none d-none">
                                    <span class="font-weight-500">{{ invoice.invoice_number }}</span>
                                    <div class="position-absolute" style="bottom:10px;right:10px;">
                                        <span class="d-block">{{ invoice.address_from.phone }}</span>
                                        <span class="d-block">{{ invoice.address_from.email }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <div class="title-with-bar">
                                        <h6>Registered For</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="row contents mt-2">
                                <div class="col-md-6 col-sm-12 col-12">
                                    <p>
                                        <span class="font-weight-500">{{invoice.address_to.firstname}}</span> <br />
                                        {{ invoice.address_to.address1 }} <br />
                                        {{ invoice.address_to.address2 }} <br />
                                        {{ invoice.address_to.zipcode }}, {{ invoice.address_to.city }}  <br />
                                        {{ invoice.address_to.province }} <br />
                                        {{ invoice.address_to.phone }} <br />
                                        {{ invoice.address_to.email }}
                                    </p>
                                </div>
                                <div class="col-md-6 col-sm-12 col-12 text-lg-right text-md-right">
                                    <p v-if="scheduleMode != 'custom'">
                                        <span class="font-weight-500">Booking for</span> <br />
                                        {{ bookingDate }} <br />
                                        {{ bookingTimeStart }} - {{ bookingTimeEnd }}
                                    </p>
                                </div>
                            </div>
                            <div v-for="line_item in invoice.line_items" :key="line_item.id">
                                <div v-if="line_item.items.length > 0" class="row mt-4">
                                    <div class="col-12">
                                        <div class="title-with-bar">
                                            <h6>{{ line_item.title }}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="line_item.items.length > 0" class="row contents mt-2">
                                    <div class="col-12">
                                        <div v-for="(item, ind) in line_item.items" :key="item.id" class="row" :class="{ 'mt-1' : ind > 0 }">
                                            <div class="col-md-10 col-sm-8 col-7">
                                                <span>{{ ind+1 }}. {{ item.name }}.</span>
                                            </div>
                                            <div class="col-md-2 col-sm-4 col-5 text-right">
                                                <span v-if="!line_item.is_hide_item_total">RM {{ (item.total).toFixed(2) }} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="line_item.items.length > 0" class="row mt-2">
                                    <div class="col-12">
                                        <div class="div-separator"></div>
                                    </div>
                                </div>
                                <div v-if="line_item.items.length > 0" class="row contents mt-1">
                                    <div class="col-12 text-right">
                                        <span class="mr-3">AMOUNT</span>
                                        <span class="font-weight-bold">RM {{ (line_item.total).toFixed(2) }}</span>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row mt-4">
                                <div class="col-12">
                                    <div class="title-with-bar">
                                        <div class="row">
                                            <div class="col-6">
                                                <h6>CALCULATION</h6>
                                            </div>
                                            <div class="col-6 text-right">
                                                <h6>CHARGES</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row contents mt-2">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="offset-md-3 col-md-5 col-sm-8 col-7">
                                            <span>Subtotal</span>
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-5 text-right">
                                            <span>RM {{ (invoice.subtotal).toFixed(2) }}</span>
                                        </div>
                                    </div>
                                    <div class="row mt-1">
                                        <div class="offset-md-3 col-md-5 col-sm-8 col-7">
                                            <span>Processing Fee</span>
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-5 text-right">
                                            <span>RM {{ (invoice.processing_fee).toFixed(2) }}</span>
                                        </div>
                                    </div>
                                    <div class="row mt-1">
                                        <div class="offset-md-3 col-md-5 col-sm-8 col-7">
                                            <span>Deposit (Reimbursable)</span>
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-5 text-right">
                                            <span>RM {{ (invoice.deposit_fee).toFixed(2) }}</span>
                                        </div>
                                    </div>
                                    <div class="row mt-1">
                                        <div class="offset-md-3 col-md-5 col-sm-8 col-7">
                                            <span>Transportation Fee (Fixed Fee)</span>
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-5 text-right">
                                            <span>RM {{ (invoice.transportation_fee).toFixed(2) }}</span>
                                        </div>
                                    </div>
                                    <!-- <div class="row mt-1">
                                        <div class="offset-md-3 col-md-5 col-sm-8 col-7">
                                            <span>SST (6%)</span>
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-5 text-right">
                                            <span>RM {{ (invoice.tax).toFixed(2) }}</span>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <div class="title-with-green-bar">
                                        <div class="row">
                                            <div class="offset-md-5 col-md-4 col-sm-6 col-6 text-md-right">
                                                <h5>TOTAL AMOUNT</h5>
                                            </div>
                                            <div class="col-md-3 col-sm-6 col-6 text-right">
                                                <h5 class="font-weight-bold">RM {{ (invoice.total).toFixed(2) }}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 bottom-buttons">
                        <button class="btn btn-cancel" @click="goPrevious">Back</button>
                        <button class="btn btn-submit float-right" @click="submitAllData"><i class="fas fa-credit-card"></i> Proceed Payment</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props: ['category', 'invoice', 'scheduleMode', 'isConfirmed'],
    data(){
        return {
            // isConfirmed: false,
            // bookingDate: null,
            // bookingTimeStart: null,
            // bookingTimeEnd: null
        }
    },
    computed: {
        bookingDate(){
            return moment(this.invoice.booking_start_datetime).format('dddd, D MMMM YYYY')
        },
        bookingTimeStart(){
            return moment(this.invoice.booking_start_datetime).format('hh:mm A')
        },
        bookingTimeEnd(){
            return moment(this.invoice.booking_end_datetime).format('hh:mm A')
        }
    },
    methods: {
        goPrevious(){
            this.$emit('go-to', 3)
        },
        submitAllData(){
            //scroll top
            window.scrollTo(0,0)
            
            this.$emit('submit-all-data')
        }
    }
}
</script>

<style scoped>
.bg-form {
    /* background-color: #E5E5E5; */
}

.top-title {
    text-align: center;
    background-color: #EB5757;
    color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 8px 0px;
}

.bottom-info {
    color: #ffffff;
    font-weight: 500;
    text-transform: uppercase;
    background-color: #A91A18;
    margin: -5px 0px 0px 0px;
    padding: 15px 0px;
    border-radius: 8px;
    letter-spacing: 1px;
}
.bottom-info .text-not-yet {
    color: #EB5757;
}

.cards {

}

.card {
    border: unset;
    padding: 15px 40px;
}

.card .card-body .contents {
    padding: 15px 35px 10px 35px;
    font-size: 15px;
}

.div-separator {
    height: 2px;
    background-color: #EBEEF2;
}

.title-with-bar {
    padding: 15px 35px 10px 35px;
    background-color: #EBEEF2;
}
.title-with-green-bar {
    padding: 20px 35px 15px 35px;
    background-color: #7CC6D6;
}

span.text-big {
    font-size: 18px;
    font-weight: 500;
}

.btn.btn-add {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    background-color: #EBEEF2;
    border: 1px solid #DADEE3;
    border-radius: 6px;
    padding: 12px 25px;
}
.btn.btn-cancel {
    color: #ffffff;
    background-color: #858C94;
    padding: 15px 25px;
}
.btn.btn-submit {
    color: #ffffff;
    background-color: #7CC6D6;
    padding: 15px 100px;
}

/** Media Queries */
@media (min-width: 992px) {
    .sub-container {
        padding: 0px 150px;
    }
}
@media (max-width: 766px) {
    .card .card-body {
        padding: 0px;
    }
}
@media (max-width: 570px) {
    .bottom-info {
        font-size: 9px;
    }
    .bottom-buttons {
        height: 140px;
    }
}
@media (max-width: 500px) {
    .card {
        padding: 15px 10px;
    }

    .btn.btn-submit {
        width: 100%;
    }
    .btn.btn-cancel {
        width: 100%;
        margin-bottom: 10px;
    }
}


/** Others */
.font-weight-500 {
    font-weight: 500;
}
</style>