import API from './API'

export default (to, from, next) => {
    API.get('/application-setting')
        .then(res => {
            //Checking maintenance mode
            if(res.data.is_maintenance && to.path != '/will-be-back-soon') next('/will-be-back-soon')
            else if(!res.data.is_maintenance && to.path == '/will-be-back-soon') next('/')
            else next()
            // next()
        })
        .catch(err => {
            //no record has been created yet
            if(err.response.status == 404)
                next()

            
            // //if request failed, means there is a problem with the backend server
            // //hence, redirect to specific page
            // if(err.request){
            //     console.log('yeah')
            //     next('/will-be-back-soon')
            // }
            // //no record has been created yet
            // if(err.response){
            //     if(err.response.status == 404)
            //     next()
            // }
        })
}